import React from "react";
import { FaHome } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { useAuthStore } from "./hooks/useAuth";

const Sidebar = () => {
	const clearAccessToken = useAuthStore((state) => state.clearAccessToken);
	return (
		<div className="w-[15vw] bg-gray-section flex flex-col items-center py-10">
			<div className="w-full text-2xl font-bold mb-10 pl-4 text-left">
				LiveRamp
			</div>

			{/* Sidebar items centered */}
			{/* Dashboard button */}
			<button
				type="button"
				className="bg-button-color text-dark-text rounded-lg w-4/5 py-2 flex items-center justify-center space-x-2"
			>
				<FaHome />
				<span>Dashboard</span>
			</button>

			{/* Spacer */}
			<div className="flex-grow" />
			<div
				className="text-dark-text w-full flex items-center justify-center space-x-2 cursor-pointer"
				onClick={() => {
					clearAccessToken();
					window.location.href = "/";
				}}
				onKeyUp={() => {
					clearAccessToken();
					window.location.href = "/";
				}}
			>
				<span>Logout</span>
				<MdLogout />
			</div>
		</div>
	);
};

export default Sidebar;
