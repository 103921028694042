import { Button } from "./components/button";

function LoginPage() {
	const initiateLogin = () => {
		const cognitoLoginUrl = new URL(process.env.REACT_APP_COGNITO_AUTH_URL || '');
		cognitoLoginUrl.searchParams.append("response_type", "code");
		cognitoLoginUrl.searchParams.append("scope", "email openid phone");
		cognitoLoginUrl.searchParams.append(
			"client_id",
			process.env.REACT_APP_CLIENT_ID || '',
		);
    	cognitoLoginUrl.searchParams.append("redirect_uri", window.location.origin);
		window.location.href = cognitoLoginUrl.toString();
	};

	return (
		<div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
					Sign in to your account
				</h2>
			</div>

			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
					<div className="space-y-6">
						<Button
							onClick={() => initiateLogin()}
							className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
						>
							Sign in with Azure AD
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LoginPage;
