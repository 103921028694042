"use client";

import {
	Popover,
	PopoverTrigger,
	PopoverContent,
} from "@radix-ui/react-popover";
import {
	Command,
	CommandInput,
	CommandEmpty,
	CommandGroup,
	CommandList,
} from "cmdk";
import ClipLoader from "react-spinners/ClipLoader";
import { Check, ChevronsUpDown } from "lucide-react";
import { Button } from "../components/button";
import { cn } from "../lib/utils";
import React from "react";
import { CommandItem, CommandSeparator } from "./command";
import { PlusCircledIcon } from "@radix-ui/react-icons";
import { DialogTrigger } from "./dialog";

export interface ComboBoxProps {
	options: ComboItem[];
	type: string;
	classname: string;
	value: string;
	isLoading?: boolean;
	setValue: React.Dispatch<React.SetStateAction<string>>;
	formDataChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	isAddOption?: boolean;
	setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ComboItem {
	label: string;
	value: string;
}

export function Combobox(props: ComboBoxProps) {
	const {
		options,
		type,
		classname,
		value,
		setValue,
		formDataChange,
		isLoading,
		isAddOption,
		setShowModal,
	} = props;
	const [open, setOpen] = React.useState(false);

	const buttonRef = React.useRef<HTMLButtonElement>(null);
	const [buttonWidth, setButtonWidth] = React.useState(0);

	React.useEffect(() => {
		if (buttonRef.current) {
			setButtonWidth(buttonRef.current.offsetWidth);
		}
	}, []);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					ref={buttonRef}
					className={`justify-between ${classname} rounded-lg`}
				>
					{value
						? options.find((option) => option.value === value)?.label
						: `Select ${type}...`}
					<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="p-0" style={{ width: buttonWidth }}>
				<Command className="max-w-lg w-full bg-white shadow-lg rounded-lg overflow-hidden">
					<CommandInput
						placeholder={`Search ${type}...`}
						className="w-full px-4 py-2 border-b border-gray-200 outline-none focus:ring-2 focus:ring-blue-500"
					/>
					<CommandList className="max-h-60 overflow-auto">
						<CommandGroup>
							{isLoading ? (
								<div className="flex flex-row gap-4 justify-center items-center p-4 w-full cursor-default">
									Loading...
									<ClipLoader
										color="#4068E3"
										speedMultiplier={0.7}
										size={20}
										cssOverride={{
											animation: "rotateAndScale 1s linear infinite",
										}}
									/>
								</div>
							) : options.length > 0 ? (
								options.map((option) => (
									<Command.Item
										key={option.value}
										value={option.label}
										onSelect={(currentValue) => {
											setValue(currentValue === value ? "" : option.value);
											setOpen(false);
										}}
										onChange={formDataChange}
										className="flex items-center px-4 py-2 cursor-pointer hover:bg-blue-100 select-none"
									>
										<Check
											className={cn(
												"mr-2 h-4 w-4",
												value === option.value ? "opacity-100" : "opacity-0",
											)}
										/>
										{option.label}
									</Command.Item>
								))
							) : (
								<CommandEmpty className="p-4 text-gray-500 text-center">
									No {type}s found.
								</CommandEmpty>
							)}
						</CommandGroup>
					</CommandList>
					<CommandSeparator />
					{isAddOption && setShowModal && (
						<CommandList>
							<CommandGroup>
								<DialogTrigger asChild>
									<CommandItem
										className="flex items-center px-4 py-2 cursor-pointer hover:bg-blue-100 select-none font-medium"
										onSelect={() => {
											setOpen(false);
											setShowModal(true);
										}}
									>
										<PlusCircledIcon className="mr-2 h-5 w-5" />
										Add a new child account
									</CommandItem>
								</DialogTrigger>
							</CommandGroup>
						</CommandList>
					)}
				</Command>
			</PopoverContent>
		</Popover>
	);
}
