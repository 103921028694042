import * as React from "react";
import { cn } from "../lib/utils";

import { Check, X, ChevronsUpDown } from "lucide-react";
import { Button } from "./button";
import {
	Command,
	CommandEmpty,
	CommandInput,
	CommandItem,
	CommandList,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Badge } from "./badge";

export type OptionType = {
	label: string;
	value: string;
};

interface MultiSelectProps {
	options: OptionType[];
	selected: string[];
	type: string;
	onChange: React.Dispatch<React.SetStateAction<string[]>>;
	className?: string;
	selectAll: boolean;
	disabled?: boolean;
}

function MultiSelect({
	options,
	selected,
	onChange,
	className,
	selectAll,
	disabled,
	...props
}: MultiSelectProps) {
	const [open, setOpen] = React.useState(false);
	const [allSelected, setAllSelected] = React.useState(false);
	const buttonRef = React.useRef<HTMLButtonElement>(null);
	const [buttonWidth, setButtonWidth] = React.useState(0);

	const handleUnselect = (item: string) => {
		onChange(selected.filter((i) => i !== item));
	};

	React.useEffect(() => {
		if (selectAll) {
			if (selected.length === options.length) {
				setAllSelected(true);
			} else {
				setAllSelected(false);
			}
		}
	}, [selectAll, selected, options]);

	const handleSelectAll = () => {
		if (allSelected) {
			onChange([]);
		} else {
			const allValues = options.map((option) => option.value);
			onChange(allValues);
		}
		setAllSelected(!allSelected);
	};

	React.useEffect(() => {
		if (buttonRef.current) {
			setButtonWidth(buttonRef.current.offsetWidth);
		}
	}, []);
	return (
		<Popover open={open} onOpenChange={setOpen} {...props}>
			<PopoverTrigger asChild>
				<Button
					disabled={disabled}
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className={`w-full justify-between ${
						selected.length > 1 ? "h-full" : "h-10"
					} rounded-lg border-border-color`}
					onClick={() => setOpen(!open)}
					ref={buttonRef}
				>
					<div className="flex gap-1 flex-wrap">
						{selected.map((item) => (
							<Badge
								variant="secondary"
								key={item}
								className="mt-1 font-bold"
								onClick={() => handleUnselect(item)}
							>
								{item}
								<button
									className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
									onKeyUp={(e) => {
										if (e.key === "Enter") {
											handleUnselect(item);
										}
									}}
									onMouseDown={(e) => {
										e.preventDefault();
										e.stopPropagation();
									}}
									onClick={() => handleUnselect(item)}
								>
									<X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
								</button>
							</Badge>
						))}
					</div>
					<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent
				className="w-full bg-white shadow-lg rounded-lg overflow-hidden"
				style={{ width: buttonWidth }}
			>
				<Command className={className}>
					<CommandInput placeholder="Search ..." />
					<CommandEmpty>No item found.</CommandEmpty>
					<CommandList className="max-h-64 overflow-auto">
						<CommandItem
							onSelect={handleSelectAll}
							className="flex items-center hover:bg-blue-100 cursor-pointer"
						>
							{allSelected ? "Unselect All" : "Select All"}
						</CommandItem>
						{options.map((option) => (
							<CommandItem
								key={option.value}
								onSelect={() => {
									onChange(
										selected.includes(option.value)
											? selected.filter((item) => item !== option.value)
											: [...selected, option.value],
									);
									setOpen(true);
								}}
								className="flex items-center hover:bg-blue-100 px-4 py-2 cursor-pointer"
							>
								<Check
									className={cn(
										"mr-2 h-4 w-4",
										selected.includes(option.value)
											? "opacity-100"
											: "opacity-0",
									)}
								/>
								{option.label}
							</CommandItem>
						))}
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
}

export { MultiSelect };
