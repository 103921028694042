import { useEffect, useRef } from "react";
import { create } from "zustand";
import { useState } from "react";
import { persist } from "zustand/middleware";

import { v4 as uuidv4 } from "uuid";
import {
	type NotificationItem,
	useNotificationStore,
} from "./hooks/useNotification";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { fetchOrganizations } from "./apis/api";
import type { Organization } from "./types/types";
import type { DistributionManager } from "./types/distribution";
import DistributedSegmentSection from "./DistributedSegmentManager";
import SegmentManagerSection from "./SegmentManager";
import Header from "./Header";
import DistributionManagerSection from "./DistributionManager";
import Sidebar from "./Sidebar";
import type { ComboItem } from "./components/combobox";
import OrganizationDropDown from "./components/OrganizationDropDown";

interface OrginzationState {
	orgID: string;
	setOrganizationId: (newOrgID: string) => void;
}
const useOrgStore = create<OrginzationState>()(
	persist(
		(set) => ({
			orgID: "",
			setOrganizationId: (newOrgID) => {
				set({ orgID: newOrgID });
			},
		}),
		{ name: "orgIDStore" },
	),
);
function Dashboard() {
	const { orgID, setOrganizationId } = useOrgStore();
	const [selectedCard, setSelectedCard] = useState<DistributionManager | null>(
		null,
	);
	const div1Ref = useRef<HTMLDivElement>(null);
	const div2Ref = useRef<HTMLDivElement>(null);
	const { addNotification } = useNotificationStore();

	const { data, error }: UseQueryResult<Organization[], Error> = useQuery<
		Organization[],
		Error
	>({
		queryKey: ["organizations"],
		queryFn: fetchOrganizations,
	});

	const organizations: ComboItem[] = data
		? data.map((organization) => ({
				label: organization.childAccountName,
				value: organization.organizationID,
			}))
		: [];

	useEffect(() => {
		if (error) {
			const notification: NotificationItem = {
				id: uuidv4(),
				message: error.message,
				header: "Failed Fetching Organizations",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			};
			addNotification(notification);
			console.error("Fetching organizations failed:", error);
		}
	}, [addNotification, error]);

	useEffect(() => {
		const adjustHeights = () => {
			if (div1Ref.current && div2Ref.current) {
				const div2Height = div2Ref.current.clientHeight;
				const div1Children = Array.from(div1Ref.current.children);
				const childCount = div1Children.length;
				const childHeight = `${100 / childCount}%`;

				for (const child of div1Children) {
					if (child instanceof HTMLElement) {
						child.style.height = childHeight;
						child.style.minHeight = `${div2Height / childCount}px`;
					}
				}
			}
		};

		adjustHeights();
		window.addEventListener("resize", adjustHeights);

		return () => window.removeEventListener("resize", adjustHeights);
	}, []);

	return (
		<div>
			<div className="flex min-h-screen">
				<Sidebar />
				<div className="flex flex-col flex-grow">
					<Header />
					<main className="flex justify-between">
						<div id="div1" ref={div1Ref} className="flex flex-col w-5/6 m-6 ">
							<DistributionManagerSection
								orgId={orgID}
								setSelectedCard={setSelectedCard}
								selectedCard={selectedCard}
							/>
							<SegmentManagerSection
								orgId={orgID}
								distributionManager={selectedCard}
							/>
						</div>
						<div id="div2" ref={div2Ref} className="flex flex-col w-1/2 m-6">
							<div className="z-10">
								<OrganizationDropDown
									organizations={organizations}
									setSelectedCard={setSelectedCard}
									orgID={orgID}
									setOrganizationId={setOrganizationId}
								/>
							</div>
							<DistributedSegmentSection
								orgId={orgID}
								distributionManager={selectedCard}
							/>
						</div>
					</main>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
