import type { ComboItem } from "../components/combobox";
import type { SegmentStatuses } from "../DistributedSegmentManager";
import { useAuthStore } from "../hooks/useAuth";
import type { Segment } from "../SegmentManager";
import type {
	Property,
	Destinations,
	DistributionManager,
} from "../types/distribution";
import type { Integration } from "../types/integration";
import type { SegmentStatus, SegmentsResponse } from "../types/segment";
import type { Organization, UpsertOrganizationParams } from "../types/types";

const fetchOrganizations = async (): Promise<Organization[]> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/organizations`,
		{
			method: "GET",
		},
	);

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

	const data = await response.json();
	return data as Organization[];
};

const upsertOrganization = async ({
	organizationID,
	childAccountName,
}: UpsertOrganizationParams): Promise<Organization> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const body = {
		OrganizationID: organizationID,
		ChildAccountName: childAccountName,
	};

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/organizations`,
		{
			method: "POST",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
			},
		},
	);

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

	const data = await response.json();
	return data as Organization;
};

const updateOrganization = async ({
	organizationID,
	childAccountName,
}: UpsertOrganizationParams): Promise<Organization> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const body = {
		OrganizationID: organizationID,
		ChildAccountName: childAccountName,
	};

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/organizations/${organizationID}`,
		{
			method: "PUT",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
			},
		},
	);

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

	const data = await response.json();
	return data as Organization;
};

const deleteOrganization = async (organizationID: string): Promise<string> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/organizations/${organizationID}`,
		{
			method: "DELETE",
		},
	);

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	return organizationID;
};

interface FetchDistributedSegmentsParams {
	distributionManagerId: string;
	orgId: string;
}

const fetchDistributedSegments = async ({
	distributionManagerId,
	orgId,
}: FetchDistributedSegmentsParams): Promise<SegmentStatuses[]> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/distributed-segments/${distributionManagerId}?integrationID=${distributionManagerId}`,
		{
			method: "GET",
			headers: {
				"Organization-ID": orgId,
			},
		},
	);

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

	const data = (await response.json()) as SegmentStatus[];
	const segmentStatuses: SegmentStatuses[] = data.map(
		(segment: SegmentStatus) => ({
			name: segment.field.name,
			liveRampID: segment.field.id,
			audienceID: segment.field.audienceId,
			status: segment.status,
		}),
	);

	return segmentStatuses;
};

interface FetchSegmentsParams {
	orgId: string;
}

const fetchSegments = async ({
	orgId,
}: FetchSegmentsParams): Promise<Segment[]> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/segments`,
		{
			method: "GET",
			headers: {
				"Organization-ID": orgId,
			},
		},
	);

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

	const data = (await response.json()) as SegmentsResponse[];
	const segments: Segment[] = data.map((segment: SegmentsResponse) => ({
		name: segment.field.name,
		segmentID: segment.id,
		liveRampID: segment.field.id,
		audienceID: segment.field.audienceId,
	}));

	return segments;
};

export interface SendSegmentsParams {
	orgId: string;
	distributionManagerId: string;
	segmentIDs: string[];
}

const sendSegments = async ({
	orgId,
	distributionManagerId,
	segmentIDs,
}: SendSegmentsParams): Promise<boolean> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const body = {
		distribution_manager_id: distributionManagerId,
		segment_ids: segmentIDs,
	};

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/send-segments`,
		{
			method: "POST",
			headers: {
				"Organization-ID": orgId,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		},
	);

	if (!response.ok) {
		throw new Error(
			`status: ${response.status}\n response: ${await response.text()}`,
		);
	}

	return true;
};

interface FetchIntegrationTypesParams {
	orgId: string;
	destinationId: string;
}

const fetchIntegrationTypes = async ({
	orgId,
	destinationId,
}: FetchIntegrationTypesParams): Promise<ComboItem[]> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/destinations/${destinationId}/integrations`,
		{
			method: "GET",
			headers: {
				"Organization-ID": orgId,
			},
		},
	);

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

	const data = (await response.json()) as Integration[];
	return data.map((integration: Integration) => ({
		label: integration.name,
		value: integration.id,
	}));
};

interface FetchRequiredPropertiesParams {
	orgId: string;
	destinationId: string;
	integrationId: string;
}

const fetchRequiredProperties = async ({
	orgId,
	destinationId,
	integrationId,
}: FetchRequiredPropertiesParams): Promise<{
	deviceComboItems: ComboItem[];
	reqPropArr: Property[];
}> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/destinations/${destinationId}/integrations/${integrationId}`,
		{
			method: "GET",
			headers: {
				"Organization-ID": orgId,
			},
		},
	);

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

	const data = await response.json();
	const deviceComboItems: ComboItem[] = data.deviceTypes.map(
		(deviceType: string) => ({
			label: deviceType,
			value: deviceType,
		}),
	);

	const reqPropArr: Property[] = data.requiredProperties.map(
		(reqProp: Property) => ({
			name: reqProp.name,
			description: reqProp.description,
			value: "",
		}),
	);

	return { deviceComboItems, reqPropArr };
};

export interface CreateDistributionManagerParams {
	orgId: string;
	distributionManagerName: string;
	destinationId: string;
	integrationId: string;
	selectedDeviceTypes: string[];
	properties: Property[];
	expireAtDate?: Date;
}

const createDistributionManager = async ({
	orgId,
	distributionManagerName,
	destinationId,
	integrationId,
	selectedDeviceTypes,
	properties,
	expireAtDate,
}: CreateDistributionManagerParams): Promise<string> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const reqProp = properties.map((prop) => ({
		propertyKey: prop.name,
		propertyValue: prop.value,
	}));

	const body = {
		custom_name: distributionManagerName,
		destination_id: destinationId,
		integration_id: integrationId,
		expire_at: expireAtDate ? expireAtDate.toISOString() : null,
		device_type: selectedDeviceTypes,
		properties: reqProp,
	};

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/distribution-managers`,
		{
			method: "POST",
			headers: {
				"Organization-ID": orgId,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		},
	);

	if (!response.ok) {
		if (response.status >= 400 && response.status < 500) {
			const data = await response.json();
			throw new Error(data.error || `${response.status}: ${data.error}`);
		}
		throw new Error(response.statusText);
	}

	const data = await response.json();
	return data.custom_name;
};

export interface UpdateDistributionManagerParams {
	orgId: string;
	distributionManagerId: string;
	distributionManagerName: string;
	integrationConnectionID: string;
	expireAtDate?: Date;
	selectedDeviceTypes: string[];
	requiredProperties: Property[];
}

const updateDistributionManager = async ({
	orgId,
	distributionManagerId,
	distributionManagerName,
	integrationConnectionID,
	expireAtDate,
	selectedDeviceTypes,
	requiredProperties,
}: UpdateDistributionManagerParams): Promise<string> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;
	console.log("im");
	const body = {
		custom_name: distributionManagerName,
		integration_conn_id: integrationConnectionID,
		expire_at: expireAtDate ? expireAtDate.toISOString() : null,
		device_type: selectedDeviceTypes,
		properties: requiredProperties.map((prop) => ({
			propertyKey: prop.name,
			propertyValue: prop.value,
		})),
	};

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/distribution-managers/${distributionManagerId}`,
		{
			method: "PUT",
			headers: {
				"Organization-ID": orgId,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		},
	);

	if (!response.ok) {
		throw new Error(
			`status: ${response.status}\n response: ${await response.text()}`,
		);
	}

	return distributionManagerName;
};

interface FetchDestinationsParams {
	orgId: string;
}

const fetchDestinations = async ({
	orgId,
}: FetchDestinationsParams): Promise<Map<string, Destinations>> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/destinations`,
		{
			method: "GET",
			headers: {
				"Organization-ID": orgId,
			},
		},
	);

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

	const data = await response.json();
	const map = new Map();
	for (const destination of data) {
		map.set(destination.id, destination);
	}

	return map;
};

interface FetchDistributedManagersParams {
	orgId: string;
}

const fetchAllDistributedManagers = async ({
	orgId,
}: FetchDistributedManagersParams): Promise<DistributionManager[]> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/distribution-managers`,
		{
			method: "GET",
			headers: {
				"Organization-ID": orgId,
			},
		},
	);

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

	const data = (await response.json()) as DistributionManager[];
	data.sort((a, b) => a.name.localeCompare(b.name));
	return data;
};

export interface DistributionManagerParams {
	orgId: string;
	distributionManagerId: string;
}

const deleteDistributionManager = async ({
	orgId,
	distributionManagerId,
}: DistributionManagerParams): Promise<string> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/distribution-managers/${distributionManagerId}`,
		{
			method: "DELETE",
			headers: {
				"Organization-ID": orgId,
			},
		},
	);

	if (!response.ok) {
		throw new Error(
			`status: ${response.status}\n response: ${await response.text()}`,
		);
	}

	return distributionManagerId;
};

export interface DistributionManagerResp {
	id: string;
	name: string;
	integrationConnection: IntegrationConnection;
	status: string;
	expireAt: string | undefined;
	createdAt: string;
	updatedAt: string;
}

interface IntegrationConnection {
	name: string;
	id: string;
	integrationID: string[];
	createdAt: string;
	updatedAt: string;
	properties: Property[];
	deviceTypes: DeviceType[];
}

interface DeviceType {
	name: string;
	selected: boolean;
}

const fetchDistributionManager = async ({
	orgId,
	distributionManagerId,
}: DistributionManagerParams): Promise<DistributionManagerResp> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/distribution-managers/${distributionManagerId}`,
		{
			method: "GET",
			headers: {
				"Organization-ID": orgId,
			},
		},
	);

	if (!response.ok) {
		throw new Error(
			`status: ${response.status}\n response: ${await response.text()}`,
		);
	}

	const data = await response.json();
	return data as DistributionManagerResp;
};


export interface SoftDeleteDistributionManagerParams
	extends DistributionManagerParams {
	deletionDelayHours: number;
}

const softDeleteDistributionManager = async ({
	deletionDelayHours,
	orgId,
	distributionManagerId,
}: SoftDeleteDistributionManagerParams): Promise<void> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;
	const body = {
		deletionDelayHours: deletionDelayHours,
	};

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/distribution-managers/${distributionManagerId}/soft-delete`,
		{
			method: "POST",
			headers: {
				"Organization-ID": orgId,
			},
			body: JSON.stringify(body),
		},
	);

	if (!response.ok) {
		throw new Error(
			`status: ${response.status}\n response: ${await response.text()}`,
		);
	}
};

const cancelSoftDelete = async ({
	orgId,
	distributionManagerId,
}: DistributionManagerParams): Promise<void> => {
	const fetchWithAuth = useAuthStore.getState().fetchWithAuth;

	const response = await fetchWithAuth(
		`${process.env.REACT_APP_API_URL}/api/v1/distribution-managers/${distributionManagerId}/cancel-deletion`,
		{
			method: "DELETE",
			headers: {
				"Organization-ID": orgId,
			},
		},
	);

	if (!response.ok) {
		throw new Error(
			`status: ${response.status}\n response: ${await response.text()}`,
		);
	}
};

export {
	fetchOrganizations,
	upsertOrganization,
	updateOrganization,
	deleteOrganization,
	fetchDistributedSegments,
	fetchSegments,
	sendSegments,
	fetchIntegrationTypes,
	fetchRequiredProperties,
	createDistributionManager,
	updateDistributionManager,
	fetchDestinations,
	fetchAllDistributedManagers,
	deleteDistributionManager,
	fetchDistributionManager,
	softDeleteDistributionManager,
  cancelSoftDelete,
};
