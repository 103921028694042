import { useAuthStore } from "../hooks/useAuth";

const exchangeCodeForTokens = async (code: string) => {
	try {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/api/v1/token-exchange`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ code: code }),
				credentials: "include",
			},
		);

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const data = await response.json();
		useAuthStore.getState().setAccessToken(data.access_token);
	} catch (error) {
		console.error("Error exchanging code for tokens:", error);
		throw error;
	}
};
export default exchangeCodeForTokens;
