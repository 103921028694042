import { useEffect, useRef } from "react";
import { useReactTable } from "./hooks/useReactTable";
import {
	type ColumnDef,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
} from "@tanstack/table-core";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { Button } from "./components/button";
import PulseLoader from "react-spinners/PulseLoader";
import { IoSearchOutline } from "react-icons/io5";

import { flexRender } from "@tanstack/react-table";
import {
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
	Table,
} from "./components/table";
import { Progress } from "./components/progress";
import { Label } from "@radix-ui/react-dropdown-menu";
import { Input } from "./components/input";
import { v4 as uuidv4 } from "uuid";

import {
	type NotificationItem,
	useNotificationStore,
} from "./hooks/useNotification";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { fetchDistributedSegments } from "./apis/api";
import type { DistributionManager } from "./types/distribution";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "./components/tooltip";
import type { Status } from "./types/segment";

const columns: ColumnDef<SegmentStatuses>[] = [
	{
		accessorKey: "name",
		header: ({ column }) => {
			return (
				<Button
					variant="ghost"
					onClick={() => {
						column.toggleSorting(column.getIsSorted() === "asc");
					}}
				>
					Name
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			);
		},
		cell: ({ row }) => {
			const text = row.getValue("name") as string;
			const isLongText = text.length > 40;
			const truncatedText = isLongText ? `${text.slice(0, 35)}...` : text;

			return (
				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger asChild>
							<div className="font-medium select-none">{truncatedText}</div>
						</TooltipTrigger>
						{isLongText && (
							<TooltipContent>
								<div className="font-medium bg-white rounded-md p-2 text-dark-text shadow-lg border-border-color">
									{text}
								</div>
							</TooltipContent>
						)}
					</Tooltip>
				</TooltipProvider>
			);
		},
	},
	{
		id: "status",
		header: () => <div>Status</div>,
		cell: ({ row }) => {
			const segment = row.original;
			let value = 0;
			let color = "";
			let status = "";
			switch (segment.status) {
				case "ready":
					value = 100;
					color = "bg-gray-500";
					status = segment.status[0].toUpperCase() + segment.status.slice(1);
					break;
				case "queue":
					value = 40;
					color = "bg-indigo-500";
					status = segment.status[0].toUpperCase() + segment.status.slice(1);
					break;
				case "in progress":
					value = 70;
					color = "bg-yellow-500";
					status = segment.status[0].toUpperCase() + segment.status.slice(1);
					break;
				case "delivered":
					value = 100;
					color = "bg-green-500";
					status = segment.status[0].toUpperCase() + segment.status.slice(1);
					break;
			}
			return (
				<Label className="font-bold text-sm select-none">
					{status}
					<Progress value={value} className="w-full`" indicatorColor={color} />
				</Label>
			);
		},
	},
];

export interface SegmentStatuses {
	name: string;
	liveRampID: string;
	audienceID: string;
	status: Status;
}

interface DistributedSegmentSectionProps {
	orgId: string;
	distributionManager: DistributionManager | null;
}

const DistributedSegmentSection = (props: DistributedSegmentSectionProps) => {
	const { orgId, distributionManager } = props;

	const tableContainerRef = useRef<HTMLDivElement>(null);
	const { addNotification } = useNotificationStore();

	const {
		data: segmentStatuses,
		error,
		isFetching,
	}: UseQueryResult<SegmentStatuses[], Error> = useQuery<
		SegmentStatuses[],
		Error
	>({
		queryKey: ["distributedSegments", distributionManager?.id, orgId],
		queryFn: () => {
			if (distributionManager !== null) {
				return fetchDistributedSegments({
					distributionManagerId: distributionManager.id,
					orgId,
				});
			}
			return [];
		},
		enabled: distributionManager !== null && orgId !== "",
		initialData: [],
	});

	useEffect(() => {
		if (error) {
			const notification: NotificationItem = {
				id: uuidv4(),
				message: error.message,
				header: "Failed Fetching Distributed Segments",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			};
			addNotification(notification);
			console.error("Fetching distributed segments failed:", error);
		}
	}, [addNotification, error]);

	const table = useReactTable({
		data: segmentStatuses,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
	});

	return (
		<div className="flex flex-col bg-section-color shadow-lg rounded-lg p-6 w-full mt-8 flex-grow relative">
			{/**/}
			{/* Title and Search/Add */}
			<div>
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-3xl font-extrabold text-dark-text">
						Distributed Segments
					</h2>
					<Label className="relative block w-2/6">
						<span className="absolute inset-y-0 left-0 flex items-center pl-2">
							<IoSearchOutline />
						</span>
						<Input
							placeholder="Filter segments..."
							value={
								(table.getColumn("name")?.getFilterValue() as string) ?? ""
							}
							onChange={(event) =>
								table.getColumn("name")?.setFilterValue(event.target.value)
							}
							className="border placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm border-malibu-600"
						/>
					</Label>
				</div>
			</div>

			{isFetching && (
				<div className="absolute inset-0 flex justify-center items-center">
					<PulseLoader color="#4068E3" speedMultiplier={0.7} size={15} />
				</div>
			)}

			{/* Partner list, allowed to grow as needed */}
			<div className="flex-grow relative flex justify-center">
				<div className="w-full pt-8">
					<div
						className="rounded-lg overflow-auto relative h-[66vh]"
						ref={tableContainerRef}
					>
						<Table>
							<TableHeader>
								{table.getHeaderGroups().map((headerGroup) => (
									<TableRow key={headerGroup.id}>
										{headerGroup.headers.map((header, index) => {
											return (
												<TableHead
													key={header.id}
													className={`text-black ${index === 0 ? "w-1/2" : ""}`}
												>
													{header.isPlaceholder
														? null
														: flexRender(
																header.column.columnDef.header,
																header.getContext(),
															)}
												</TableHead>
											);
										})}
									</TableRow>
								))}
							</TableHeader>
							<TableBody>
								{isFetching ? (
									<></>
								) : table.getRowModel().rows?.length ? (
									table.getRowModel().rows.map((row) => (
										<TableRow
											key={row.id}
											data-state={row.getIsSelected() && "selected"}
										>
											{row.getVisibleCells().map((cell) => (
												<TableCell key={cell.id}>
													{flexRender(
														cell.column.columnDef.cell,
														cell.getContext(),
													)}
												</TableCell>
											))}
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell
											colSpan={columns.length}
											className="h-24 text-center font-semibold"
										>
											No segments.
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DistributedSegmentSection;
