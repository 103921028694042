import { useState, useMemo } from "react";

interface UsePaginationProps {
	totalItems: number;
	itemsPerPage: number;
	initialPage?: number;
	siblingCount?: number;
}

interface UsePaginationResult {
	currentPage: number;
	totalPages: number;
	pageNumbers: (number | string)[];
	goToPage: (page: number) => void;
	goToNextPage: () => void;
	goToPreviousPage: () => void;
	canGoToNextPage: boolean;
	canGoToPreviousPage: boolean;
}

const DOTS = "...";

export const usePagination = ({
	totalItems,
	itemsPerPage,
	initialPage = 1,
	siblingCount = 1,
}: UsePaginationProps): UsePaginationResult => {
	const [currentPage, setCurrentPage] = useState(initialPage);

	const totalPages = useMemo(
		() => Math.ceil(totalItems / itemsPerPage),
		[totalItems, itemsPerPage],
	);

	const pageNumbers = useMemo(() => {
		const totalNumbers = siblingCount * 2 + 5;
		if (totalPages <= totalNumbers) {
			return Array.from({ length: totalPages }, (_, i) => i + 1);
		}

		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);

		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			const leftRange = Array.from(
				{ length: 3 + 2 * siblingCount },
				(_, i) => i + 1,
			);
			return [...leftRange, DOTS, totalPages];
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			const rightRange = Array.from(
				{ length: 3 + 2 * siblingCount },
				(_, i) => totalPages - (3 + 2 * siblingCount) + i + 1,
			);
			return [1, DOTS, ...rightRange];
		}

		if (shouldShowLeftDots && shouldShowRightDots) {
			const middleRange = Array.from(
				{ length: rightSiblingIndex - leftSiblingIndex + 1 },
				(_, i) => leftSiblingIndex + i,
			);
			return [1, DOTS, ...middleRange, DOTS, totalPages];
		}

		return Array.from(
			{ length: Math.min(totalPages, totalNumbers) },
			(_, i) => i + 1,
		);
	}, [totalPages, currentPage, siblingCount]);

	const goToPage = (page: number) => {
		if (page >= 1 && page <= totalPages) {
			setCurrentPage(page);
		}
	};

	const goToNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage((prev) => prev + 1);
		}
	};

	const goToPreviousPage = () => {
		if (currentPage > 1) {
			setCurrentPage((prev) => prev - 1);
		}
	};

	return {
		currentPage,
		totalPages,
		pageNumbers,
		goToPage,
		goToNextPage,
		goToPreviousPage,
		canGoToNextPage: currentPage < totalPages,
		canGoToPreviousPage: currentPage > 1,
	};
};