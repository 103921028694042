import { create } from "zustand";
import { persist } from "zustand/middleware";

interface AuthState {
	accessToken: string;
	setAccessToken: (newAccessToken: string) => void;
	clearAccessToken: () => void;
	refreshAccessToken: () => Promise<string>;
	fetchWithAuth: (url: string, options?: RequestInit) => Promise<Response>;
}

const useAuthStore = create<AuthState>()(
	persist(
		(set, get) => ({
			accessToken: "",
			// Function to set the access token
			setAccessToken: (token: string) => set({ accessToken: token }),
			// Function to clear the access token
			clearAccessToken: () => {
				localStorage.clear();
			},
			refreshAccessToken: async () => {
				try {
					const response = await fetch(
						`${process.env.REACT_APP_API_URL}/api/v1/refresh-token`,
						{
							method: "POST",
							credentials: "include",
							headers: {
								"Content-Type": "application/json",
							},
						},
					);
					if (!response.ok) throw new Error("Token refresh failed");
					const data = await response.json();
					set({ accessToken: data.accessToken });
					return data.accessToken;
				} catch (error) {
					set({ accessToken: "" });
					throw error;
				}
			},
			fetchWithAuth: async (
				url: string,
				options: RequestInit = {},
			): Promise<Response> => {
				const {
					accessToken,
					setAccessToken,
					clearAccessToken,
					refreshAccessToken,
				} = get();
				let response: Response;
				try {
					response = await fetch(url, {
						...options,
						headers: {
							...options.headers,
							Authorization: `Bearer ${accessToken}`,
						},
					});
				} catch (error) {
					if (
						!(error instanceof TypeError) &&
						!(error instanceof Error && error.name === "AbortError")
					) {
						clearAccessToken();
						throw new Error("Error sending request, please log in again.");
					}

					throw new Error("Error sending request.");
				}

				if (response.status === 401) {
					try {
						const newAccessToken = await refreshAccessToken();
						if (newAccessToken) {
							setAccessToken(newAccessToken);
							return fetch(url, {
								...options,
								headers: {
									...options.headers,
									Authorization: `Bearer ${newAccessToken}`,
								},
								credentials: "include",
							});
						}
					} catch (error) {
						if (
							!(error instanceof TypeError) &&
							!(error instanceof Error && error.name === "AbortError")
						) {
							clearAccessToken();
							throw new Error("Session has expired, please log in again.");
						}
						throw new Error("Error sending request.");
					}
				}

				return response;
			},
		}),
		{ name: "accessTokenStore" },
	),
);

export { useAuthStore };
