import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface NotificationItem {
	id: string;
	message?: string;
	header: string;
	notification_type: NotificationType;
	timestamp: string;
	isRead?: boolean;
}

type NotificationType = "error" | "success";

interface NotificationState {
	notifications: NotificationItem[];
	removeNotification: (id: string) => void;
	addNotification: (notification: NotificationItem) => void;
	readNotification: () => void;
	clearNotifications: () => void;
}

const useNotificationStore = create<NotificationState>()(
	persist(
		(set, get) => ({
			notifications: [],
			removeNotification: (id: string) =>
				set({
					notifications: get().notifications.filter((notif) => notif.id !== id),
				}),
			addNotification: (notification: NotificationItem) =>
				set({
					notifications: [
						{
							...notification,
							isRead: true,
						},
						...get().notifications,
					],
				}),
			readNotification: () => {
				set({
					notifications: get().notifications.map((notification) => ({
						...notification,
						isRead: false,
					})),
				});
			},
			clearNotifications: () => set({ notifications: [] }),
		}),
		{ name: "notificationStore" },
	),
);

export { useNotificationStore };
