import {
	Cross2Icon,
	PlusCircledIcon,
	Pencil1Icon,
} from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-label";
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
} from "@radix-ui/react-popover";
import {
	CommandInput,
	CommandList,
	CommandGroup,
	CommandEmpty,
	CommandItem,
	Command,
} from "cmdk";
import { ChevronsUpDown, Check } from "lucide-react";
import { useState, useRef, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { v4 } from "uuid";
import { useNotificationStore } from "../hooks/useNotification";
import { cn } from "../lib/utils";
import type { Organization, UpsertOrganizationParams } from "../types/types";
import {
	DialogHeader,
	DialogFooter,
	Dialog,
	DialogContent,
	DialogDescription,
	DialogTitle,
	DialogTrigger,
} from "./dialog";
import { Input } from "./input";
import { Button } from "./button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
	upsertOrganization,
	deleteOrganization,
	updateOrganization,
} from "../apis/api";
import type { DistributionManager } from "../types/distribution";
import type { ComboItem } from "./combobox";

interface OrganizationDropDownProp {
	organizations: ComboItem[];
	orgID: string;
	setSelectedCard: React.Dispatch<
		React.SetStateAction<DistributionManager | null>
	>;
	setOrganizationId: (newOrgID: string) => void;
	isLoading?: boolean;
}

function OrganizationDropDown(props: OrganizationDropDownProp) {
	const {
		organizations,
		setSelectedCard,
		setOrganizationId,
		orgID,
		isLoading,
	} = props;
	const [open, setOpen] = useState(false);

	const buttonRef = useRef<HTMLButtonElement>(null);
	const [buttonWidth, setButtonWidth] = useState(0);

	useEffect(() => {
		if (buttonRef.current) {
			setButtonWidth(buttonRef.current.offsetWidth);
		}
	}, []);

	const [showNewAddAccount, setShowNewAddAccount] = useState(false);
	const [showEditAccount, setShowEditAccount] = useState<boolean>(false);
	const [organization, setOrganization] = useState<string>("");
	const [accountName, setAccountName] = useState<string>("");
	const [activeModalId, setActiveModalId] = useState<string | null>(null);
	const handleEditOrganization = (org: ComboItem) => {
		setOrganization(org.value);
		setAccountName(org.label);
		setShowEditAccount(true);
		setOpen(false);
	};

	const queryClient = useQueryClient();

	const { addNotification } = useNotificationStore();

	const upsertMutation = useMutation<
		Organization,
		Error,
		UpsertOrganizationParams
	>({
		mutationFn: upsertOrganization,
		onSuccess: (data) => {
			addNotification({
				id: v4(),
				message: `${data.childAccountName} has been added`,
				header: "Successfully Added Organization",
				notification_type: "success",
				timestamp: new Date().toISOString(),
			});
			setShowNewAddAccount(false);
			queryClient.invalidateQueries({ queryKey: ["organizations"] });
		},
		onError: (error) => {
			addNotification({
				id: v4(),
				message:
					error instanceof Error
						? error.message
						: "There was an error trying to add organization.",
				header: "Failed Adding Organization",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			});
			return `An error trying to add the account: ${error}`;
		},
	});

	const updateMutation = useMutation<
		Organization,
		Error,
		UpsertOrganizationParams
	>({
		mutationFn: updateOrganization,
		onSuccess: (data) => {
			addNotification({
				id: v4(),
				message: `${data.childAccountName} has been updated`,
				header: "Successfully Updated Organization",
				notification_type: "success",
				timestamp: new Date().toISOString(),
			});
			setShowNewAddAccount(false);
			queryClient.invalidateQueries({ queryKey: ["organizations"] });
		},
		onError: (error) => {
			addNotification({
				id: v4(),
				message:
					error instanceof Error
						? error.message
						: "There was an error trying to update the organization.",
				header: "Failed Updating Organization",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			});
			return `An error trying to update the account: ${error}`;
		},
	});

	const deleteMutation = useMutation<string, Error, string>({
		mutationFn: deleteOrganization,
		onSuccess: (data) => {
			addNotification({
				id: v4(),
				header: "Successfully Deleted Organization",
				message: `Organziation id, ${data} has been deleted`,
				notification_type: "success",
				timestamp: new Date().toISOString(),
			});
			setActiveModalId("");
			queryClient.invalidateQueries({ queryKey: ["organizations"] });
		},
		onError: (error) => {
			addNotification({
				id: v4(),
				message:
					error instanceof Error
						? error.message
						: "There was an error trying to delete organization.",
				header: "Failed Deleting Organization",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			});
			console.error("Delete organization failed:", error);
		},
	});

	const handleAddOrganization = () => {
		upsertMutation.mutate({
			organizationID: organization,
			childAccountName: accountName,
		});
	};

	const handleDeleteOrganization = (organizationID: string) => {
		deleteMutation.mutate(organizationID);
	};

	const handleUpdateOrganization = () => {
		updateMutation.mutate({
			organizationID: organization,
			childAccountName: accountName,
		});
		setShowEditAccount(false);
	};

	return (
		<>
			<Dialog open={showNewAddAccount} onOpenChange={setShowNewAddAccount}>
				<Popover open={open} onOpenChange={setOpen}>
					<PopoverTrigger asChild>
						<Button
							variant="outline"
							role="combobox"
							aria-expanded={open}
							ref={buttonRef}
							className="justify-between w-full border p-2 rounded text-md rounded-lg h-full border-malibu-600"
						>
							{orgID
								? organizations.find((option) => option.value === orgID)?.label
								: "Select Organization..."}
							<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
						</Button>
					</PopoverTrigger>
					<PopoverContent className="p-0" style={{ width: buttonWidth }}>
						<Command className="bg-white shadow-lg rounded-lg overflow-hidden">
							<CommandInput
								placeholder="Search Organization..."
								className="w-full px-4 py-2 border-b border-gray-200 outline-none focus:ring-2 focus:ring-blue-500"
							/>
							<CommandList className="max-h-60 overflow-auto">
								<CommandGroup>
									{isLoading ? (
										<div className="flex flex-row gap-4 justify-center items-center p-4 w-full cursor-default">
											Loading...
											<ClipLoader
												color="#4068E3"
												speedMultiplier={0.7}
												size={20}
												cssOverride={{
													animation: "rotateAndScale 1s linear infinite",
												}}
											/>
										</div>
									) : organizations.length > 0 ? (
										organizations.map((option) => (
											<Dialog
												open={activeModalId === option.label}
												onOpenChange={(e) => {
													if (!e) {
														setActiveModalId("");
													}
												}}
												key={option.value}
											>
												<Command.Item
													key={option.value}
													value={option.label}
													onSelect={(currentValue) => {
														setOrganizationId(
															currentValue === orgID ? "" : option.value,
														);
														setSelectedCard(null);
														setOpen(false);
													}}
													className="flex items-center justify-between px-4 py-2 cursor-pointer hover:bg-blue-100 select-none"
												>
													<Check
														className={cn(
															"mr-2 h-4 w-4",
															orgID === option.value
																? "opacity-100"
																: "opacity-0",
														)}
													/>
													<span className="flex-grow text-left">
														{option.label}
													</span>
													<Button
														variant="ghost"
														onClick={(e: React.MouseEvent) => {
															e.stopPropagation();
															handleEditOrganization(option);
														}}
														className="h-8 w-8 p-0 mr-2"
													>
														<Pencil1Icon className="h-5 w-5" />
													</Button>
													<Button
														variant="ghost"
														onClick={(event) => {
															event.stopPropagation();
															setActiveModalId(option.label);
														}}
														className="h-8 w-8 p-0 ml-4"
													>
														<Cross2Icon className="h-5 w-5" />
													</Button>
												</Command.Item>
												<DialogContent className="bg-white">
													<DialogHeader>
														<DialogTitle>
															Are You Sure You Want to Delete This Account?
														</DialogTitle>
														<DialogDescription>
															Deleting this account will remove the organization
															id from the database.
														</DialogDescription>
													</DialogHeader>
													<DialogFooter>
														<Button
															variant="outline"
															onClick={() => setActiveModalId("")}
														>
															Cancel
														</Button>
														<Button
															type="submit"
															className="font-semi-bold py-2 px-4 rounded text-dark-text bg-button-color text-dark-text hover:bg-blue-400"
															onClick={() =>
																handleDeleteOrganization(option.value)
															}
														>
															Delete Account
														</Button>
													</DialogFooter>
												</DialogContent>
											</Dialog>
										))
									) : (
										<CommandEmpty className="p-4 text-gray-500 text-center">
											No Organizations found.
										</CommandEmpty>
									)}
								</CommandGroup>
							</CommandList>
							<CommandList>
								<CommandGroup>
									<DialogTrigger asChild>
										<CommandItem
											className="flex items-center px-4 py-2 cursor-pointer hover:bg-blue-100 select-none font-medium"
											onSelect={() => {
												setOpen(false);
												setShowNewAddAccount(true);
											}}
										>
											<PlusCircledIcon className="mr-2 h-5 w-5" />
											Add a new child account
										</CommandItem>
									</DialogTrigger>
								</CommandGroup>
							</CommandList>
						</Command>
					</PopoverContent>
				</Popover>
				<DialogContent className="bg-white">
					<DialogHeader>
						<DialogTitle>Add account</DialogTitle>
						<DialogDescription>
							Add a new account to manage your Segments and Distribution
							Managers.
						</DialogDescription>
					</DialogHeader>
					<div>
						<div className="space-y-4 py-2 pb-4">
							<div className="space-y-2">
								<Label htmlFor="name">Account Name</Label>
								<Input
									onChange={(e) => setAccountName(e.target.value)}
									className="border-border-color px-4 py-2 border-b placeholder:text-gray-300"
									placeholder="Example Inc"
								/>
							</div>
							<div className="space-y-2">
								<Label htmlFor="name">Organization ID</Label>
								<Input
									onChange={(e) => setOrganization(e.target.value)}
									className="border-border-color px-4 py-2 border-b placeholder:text-gray-300"
									placeholder="2598970a-a966-4868-9684-74d6ab119812"
								/>
							</div>
						</div>
					</div>
					<DialogFooter>
						<Button
							variant="outline"
							onClick={() => setShowNewAddAccount(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="font-semi-bold py-2 px-4 rounded text-dark-text bg-button-color text-dark-text hover:bg-blue-400"
							disabled={organization === "" || accountName === ""}
							onClick={handleAddOrganization}
						>
							Add
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
			{/* Edit Account Dialog */}
			<Dialog open={showEditAccount} onOpenChange={setShowEditAccount}>
				<DialogContent className="bg-white">
					<DialogHeader>
						<DialogTitle>Edit Organization</DialogTitle>
						<DialogDescription>
							Update the organization information.
						</DialogDescription>
					</DialogHeader>
					<div className="space-y-4 py-2 pb-4">
						<div className="space-y-2">
							<Label htmlFor="edit-name">Account Name</Label>
							<Input
								id="edit-name"
								value={accountName}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setAccountName(e.target.value)
								}
								className="border-border-color px-4 py-2 border-b placeholder:text-gray-300"
							/>
						</div>
						<div className="space-y-2">
							<Label htmlFor="edit-org-id">Organization ID</Label>
							<Input
								id="edit-org-id"
								value={organization}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setOrganization(e.target.value)
								}
								className="border-border-color px-4 py-2 border-b placeholder:text-gray-300"
								disabled
							/>
						</div>
					</div>
					<DialogFooter>
						<Button variant="outline" onClick={() => setShowEditAccount(false)}>
							Cancel
						</Button>
						<Button
							type="submit"
							className="font-semi-bold py-2 px-4 rounded text-dark-text bg-button-color text-dark-text hover:bg-blue-400"
							onClick={handleUpdateOrganization}
						>
							Update
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default OrganizationDropDown;
