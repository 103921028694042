import { Card, CardHeader, CardContent, CardTitle } from "./components/card";
import { useEffect, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { toast } from "sonner";
import { Input } from "./components/input";
import { Label } from "./components/label";
import { IoSearchOutline } from "react-icons/io5";
import { Button } from "./components/button";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
} from "./components/dropdownMenu";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { cn } from "./lib/utils";
import {
	type NotificationItem,
	useNotificationStore,
} from "./hooks/useNotification";
import { v4 as uuidv4 } from "uuid";
import {
	useMutation,
	useQuery,
	useQueryClient,
	type UseQueryResult,
} from "@tanstack/react-query";
import {
	type DistributionManagerParams,
	fetchDestinations,
	fetchAllDistributedManagers,
	type SoftDeleteDistributionManagerParams,
	cancelSoftDelete,
	softDeleteDistributionManager,
} from "./apis/api";
import type { Destinations, DistributionManager } from "./types/distribution";
import IntegrationEditModal from "./IntegrationEditModal";
import IntegrationModal from "./IntegrationModal";
import { isDateExpired } from "./utils/date";
import { PaginationBar } from "./PaginationBar";
import SoftDeleteDialog from "./SoftDeleteDialog";

const SOFT_DELETE_HOURS = 24;

interface DistributionManagerProps {
	orgId: string;
	setSelectedCard: React.Dispatch<
		React.SetStateAction<DistributionManager | null>
	>;
	selectedCard: DistributionManager | null;
}

const DistributionManagerSection = (props: DistributionManagerProps) => {
	const { orgId, setSelectedCard, selectedCard } = props;
	const cardsPerPage = 3;
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchQuery, setSearchQuery] = useState("");
	const [activeModalId, setActiveModalId] = useState<string | null>(null);
	const [activeDeleteModalId, setActiveDeleteModalId] = useState<string | null>(
		null,
	);
	const { addNotification } = useNotificationStore();
	const queryClient = useQueryClient();

	const softDeleteMutation = useMutation<
		void,
		Error,
		SoftDeleteDistributionManagerParams
	>({
		mutationFn: softDeleteDistributionManager,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["distributionManagers", orgId],
			});
			addNotification({
				id: uuidv4(),
				message: `${selectedCard?.name} has been marked for deletion`,
				header: "Successfully Soft Deleted Distribution Manager",
				notification_type: "success",
				timestamp: new Date().toISOString(),
			});
		},
		onError: (error) => {
			addNotification({
				id: uuidv4(),
				message: error.message,
				header: "Failed Soft Deleting Distribution Manager",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			});
			console.error("Soft deleting distribution manager failed:", error);
		},
	});

	const cancelSoftDeleteMutation = useMutation<
		void,
		Error,
		DistributionManagerParams
	>({
		mutationFn: cancelSoftDelete,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["distributionManagers", orgId],
			});
			addNotification({
				id: uuidv4(),
				message: `Soft delete cancelled for ${selectedCard?.name}`,
				header: "Successfully Cancelled Soft Delete",
				notification_type: "success",
				timestamp: new Date().toISOString(),
			});
		},
		onError: (error) => {
			addNotification({
				id: uuidv4(),
				message: error.message,
				header: "Failed Cancelling Soft Delete",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			});
			console.error("Cancelling soft delete failed:", error);
		},
	});

	const handleSoftDelete = () => {
		if (!orgId || !selectedCard) return;

		toast.promise(
			softDeleteMutation.mutateAsync({
				orgId,
				distributionManagerId: selectedCard.id,
				deletionDelayHours: SOFT_DELETE_HOURS,
			}),
			{
				loading: "Processing...",
				success: `${selectedCard.name} has been marked for deletion`,
				error: (error) =>
					`An error occurred while trying to soft delete the Distribution Manager: ${error.message}`,
			},
		);
		setActiveDeleteModalId(null);
	};

	const handleCancelSoftDelete = () => {
		if (!orgId || !selectedCard) return;

		toast.promise(
			cancelSoftDeleteMutation.mutateAsync({
				orgId,
				distributionManagerId: selectedCard.id,
			}),
			{
				loading: "Processing...",
				success: `Soft delete cancelled for ${selectedCard.name}`,
				error: (error) =>
					`An error occurred while trying to cancel soft delete: ${error.message}`,
			},
		);
		setActiveDeleteModalId(null);
	};

	const formatCountdown = (softDeletedAt: string): string => {
		const deleteTime = new Date(softDeletedAt).getTime()
		const remainingTime = deleteTime - Date.now();
		if (remainingTime <= 0) return "Deleting soon...";

		const hours = Math.floor(remainingTime / (60 * 60 * 1000));
		const minutes = Math.floor(
			(remainingTime % (60 * 60 * 1000)) / (60 * 1000),
		);
		return `${hours}h ${minutes}m remaining`;
	};

	const {
		data: destinations,
		error: destinationError,
		isFetching: destinationLoading,
	}: UseQueryResult<Map<string, Destinations>, Error> = useQuery({
		queryKey: ["destinations", orgId],
		queryFn: () => fetchDestinations({ orgId }),
		enabled: orgId !== "",
		initialData: new Map(),
	});

	const {
		data: distributionManager,
		error: distributionManagerError,
		isFetching: distributionManagerLoading,
	}: UseQueryResult<DistributionManager[], Error> = useQuery({
		queryKey: ["distributionManagers", orgId],
		queryFn: () => fetchAllDistributedManagers({ orgId }),
		enabled: orgId !== "",
		initialData: [],
	});

	useEffect(() => {
		if (destinationError) {
			const notification: NotificationItem = {
				id: uuidv4(),
				message: destinationError.message,
				header: "Failed Fetching Destinations",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			};
			addNotification(notification);
			console.error("Fetching destinations failed:", destinationError);
		}
	}, [addNotification, destinationError]);

	useEffect(() => {
		if (distributionManagerError) {
			const notification: NotificationItem = {
				id: uuidv4(),
				message: distributionManagerError.message,
				header: "Failed Fetching Distributed Managers",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			};
			addNotification(notification);
			console.error(
				"Fetching distributed managers failed:",
				distributionManagerError,
			);
		}
	}, [addNotification, distributionManagerError]);

	// Effect for filtering and pagination logic
	useEffect(() => {
		if (distributionManager) {
			const filteredCards = distributionManager.filter((distributionManager) =>
				distributionManager.name
					.toLowerCase()
					.includes(searchQuery.toLowerCase()),
			);

			// Calculate totalPages based on filtered results
			const newTotalPages = Math.ceil(filteredCards.length / cardsPerPage);
			setTotalPages(newTotalPages);

			// Adjust currentPage if out of bounds
			if (currentPage > newTotalPages) {
				setCurrentPage(newTotalPages || 1); // Ensure currentPage is at least 1
			} else if (currentPage < 1 && newTotalPages > 0) {
				setCurrentPage(1);
			}
		}
	}, [currentPage, distributionManager, searchQuery]);

	// Calculate current cards based on search and pagination
	const indexOfLastCard = currentPage * cardsPerPage;
	const indexOfFirstCard = indexOfLastCard - cardsPerPage;
	const currentCards =
		distributionManager
			?.filter((distributionManager) =>
				distributionManager.name
					.toLowerCase()
					.includes(searchQuery.toLowerCase()),
			)
			.slice(indexOfFirstCard, indexOfLastCard) || [];

	// Function to handle page click
	const handlePageClick = (pageNumber: number) => {
		if (pageNumber < 1 || pageNumber > totalPages) {
			// Do nothing if it's out of bounds
			return;
		}
		setCurrentPage(pageNumber);
	};

	const handleCardSelect = (card: DistributionManager) => {
		if (selectedCard?.id !== card.id) {
			setSelectedCard(card);
		}
	};

	const renderDropdownMenuContent = (selectedCard: DistributionManager) => (
		<DropdownMenuContent align="end" className="bg-white">
			<div
				className={cn(
					"relative flex cursor-default select-none rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
					"hover:bg-blue-100 cursor-pointer text-left",
				)}
				onClick={() => setActiveModalId(selectedCard.id)}
				onKeyUp={() => setActiveModalId(selectedCard.id)}
			>
				View/Edit Details
			</div>
			{activeModalId === selectedCard.id &&
				(() => {
					const destination = destinations.get(selectedCard.destinationID);
					if (destination) {
						return (
							<IntegrationEditModal
								orgId={orgId}
								destination={destination}
								distributionManager={selectedCard}
								open={true}
								setOpen={() => setActiveModalId(null)}
							/>
						);
					}
					return null;
				})()}
			<div
				className={cn(
					"relative flex cursor-default select-none items-center justify-start rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
					"hover:bg-blue-100 cursor-pointer",
				)}
				onClick={() => setActiveDeleteModalId(selectedCard?.id || null)}
				onKeyUp={() => setActiveDeleteModalId(selectedCard?.id || null)}
			>
				{selectedCard?.softDeletedAt ? "Cancel Soft Delete" : "Soft Delete"}
			</div>
			{activeDeleteModalId === selectedCard.id && (
				<SoftDeleteDialog
					isOpen={activeDeleteModalId === selectedCard.id}
					onClose={() => setActiveDeleteModalId(null)}
					selectedCard={selectedCard}
					onSoftDelete={handleSoftDelete}
					onCancelSoftDelete={handleCancelSoftDelete}
					SOFT_DELETE_HOURS={SOFT_DELETE_HOURS}
				/>
			)}
		</DropdownMenuContent>
	);

	// Update the card rendering to show soft delete status
	const renderCards = () => {
		return currentCards.map((card) => {
			const isExpired = card.expireAt != null && isDateExpired(card.expireAt);
			return (
				<Card
					key={card.id}
					className={`max-w-sm ${
						card.id === selectedCard?.id
							? "ring-4 ring-malibu-700 hover:-translate-y-1 duration-300"
							: ""
					} ${isExpired ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
          ${card.softDeletedAt != null ? "bg-red-100" : "bg-[#FFFFFF]"}
          flex flex-col items-center justify-center relative h-full`}
					onClick={() => !isExpired && handleCardSelect(card)}
				>
					<CardHeader className="items-center justify-center text-center h-20">
						<div className="absolute top-1 right-4">
							<DropdownMenu>
								<DropdownMenuTrigger
									asChild
									onPointerDown={(e) => {
										e.stopPropagation();
										!isExpired && handleCardSelect(card);
									}}
								>
									<Button
										variant="ghost"
										className="h-8 w-8 p-0"
										disabled={isExpired}
									>
										<DotsHorizontalIcon className="h-6 w-6" />
									</Button>
								</DropdownMenuTrigger>
								{selectedCard &&
									!isExpired &&
									renderDropdownMenuContent(selectedCard)}
							</DropdownMenu>
						</div>
						<img
							src={destinations.get(card.destinationID)?.logoUrl}
							alt=""
							className="h-8 mx-auto select-none"
						/>
					</CardHeader>
					<CardContent>
						<CardTitle className="text-center text-md font-semibold select-none">
							{card.name}
						</CardTitle>
						<CardTitle
							className={`text-sm select-none text-center ${
								isExpired ? "text-red-500" : "text-gray-500"
							}`}
						>
							{card.expireAt !== null
								? `Expire${isExpired ? "d" : "s"} At: ${card.expireAt}`
								: "No Expiry"}
						</CardTitle>
					</CardContent>
					{card.softDeletedAt != null && (
						<div className="absolute top-0 left-0 bg-red-500 text-white text-xs px-2 py-1 rounded-lg">
							Soft Deleted: {formatCountdown(card.softDeletedAt)}
						</div>
					)}
				</Card>
			);
		});
	};

	return (
		<div
			className="flex flex-col bg-section-color shadow-lg rounded-lg p-6 w-full relative"
			// style={{ height: "45vh" }}
		>
			{/**/}
			{/* Title and Search/Add */}
			<div>
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-3xl font-extrabold text-dark-text">
						Distribution Manager
					</h2>
					<div className="flex gap-4 h-full w-2/6">
						<Label className="relative block w-full">
							<span className="absolute inset-y-0 left-0 flex items-center pl-2">
								<IoSearchOutline />
							</span>
							<Input
								type="text"
								placeholder="Filter distribution managers..."
								className="border placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm font-normal border-malibu-600"
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</Label>
						<IntegrationModal orgId={orgId} destinations={destinations} />
					</div>
				</div>
			</div>

			{(distributionManagerLoading || destinationLoading) && (
				<div className="absolute inset-0 flex justify-center items-center">
					<PulseLoader color="#4068E3" speedMultiplier={0.7} size={15} />
				</div>
			)}

			{/* Partner list, allowed to grow as needed */}
			<div className="flex-grow relative flex items-center justify-center">
				{!distributionManager && orgId !== "" && (
					<div className="absolute inset-0 flex justify-center items-center">
						<PulseLoader color="#4068E3" speedMultiplier={0.7} size={15} />
					</div>
				)}
				<div className="grid grid-cols-3 items-center gap-16 w-full h-1/2">
					{destinationLoading ? (
						<></>
					) : destinations.size > 0 ? (
						renderCards()
					) : (
						<div className="h-24 text-center font-semibold col-start-2 flex justify-center items-center">
							No distribution managers.
						</div>
					)}
				</div>
			</div>

			{/* Pagination - Anchored to the bottom */}
			<div className="flex justify-center mt-4">
				<PaginationBar
					totalItems={totalPages}
					itemsPerPage={cardsPerPage}
					onPageChange={handlePageClick}
				/>
			</div>
		</div>
	);
};

export default DistributionManagerSection;
