import { Button } from "./components/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "./components/dialog";
import type { DistributionManager } from "./types/distribution";

// Interface for the component props
interface SoftDeleteDialogProps {
	isOpen: boolean;
	onClose: () => void;
	selectedCard: DistributionManager | null;
	onSoftDelete: () => void;
	onCancelSoftDelete: () => void;
	SOFT_DELETE_HOURS: number;
}

const SoftDeleteDialog: React.FC<SoftDeleteDialogProps> = ({ 
  isOpen, 
  onClose, 
  selectedCard, 
  onSoftDelete, 
  onCancelSoftDelete, 
  SOFT_DELETE_HOURS 
}) => {
	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="bg-white">
				<DialogHeader>
					<DialogTitle>
						{selectedCard?.softDeletedAt
							? "Cancel Soft Delete"
							: "Soft Delete Distribution Manager"}
					</DialogTitle>
					<DialogDescription>
						{selectedCard?.softDeletedAt
							? `Do you want to cancel the soft delete for ${selectedCard.name}?`
							: `Are you sure you want to mark ${selectedCard?.name} for deletion? It will be permanently deleted after ${SOFT_DELETE_HOURS} hours.`}
					</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<Button variant="outline" onClick={onClose}>
						Cancel
					</Button>
					<Button
						type="submit"
						className="font-semi-bold py-2 px-4 rounded text-dark-text bg-button-color text-dark-text hover:bg-blue-400"
						onClick={
							selectedCard?.softDeletedAt ? onCancelSoftDelete : onSoftDelete
						}
					>
						{selectedCard?.softDeletedAt ? "Cancel Soft Delete" : "Soft Delete"}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default SoftDeleteDialog;