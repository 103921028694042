import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Input } from "./input";
import { Label } from "./label";
import { IoSearchOutline } from "react-icons/io5";
import type { Table } from "@tanstack/react-table";
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";

export interface Tag {
	id: string;
	text: string;
}

interface MultiTagInputProps {
	//   setTags: React.Dispatch<React.SetStateAction<Tag[]>>;
	//   tags: Tag[];
	table: Table<any>;
}

function MultiTagInput(props: MultiTagInputProps): JSX.Element {
	const { table } = props;
	const [tags, setTags] = useState<Tag[]>([]);
	const [inputValue, setInputValue] = useState("");
	const ref =
		useRef<HTMLLabelElement>() as React.MutableRefObject<HTMLLabelElement>;
	const inputRef = useRef<HTMLInputElement>(null);
	const { events } = useDraggable(ref);

	const addTag = (text: string) => {
		const trimmedText = text.trim();
		if (trimmedText.length > 0) {
			setTags([...tags, { id: uuidv4(), text: trimmedText }]);
			setInputValue("");
		}
	};

	const removeTag = (id: string) => {
		setTags(tags.filter((tag) => tag.id !== id));
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if ((e.key === "Enter" || e.key === " ") && inputValue.trim()) {
			e.preventDefault();
			addTag(inputValue);
		} else if (
			(e.key === "Backspace" || e.key === "Delete") &&
			inputValue === "" &&
			tags.length > 0
		) {
			removeTag(tags[tags.length - 1].id);
		}
	};

	const handleLabelClick = (e: React.MouseEvent<HTMLLabelElement>) => {
		e.preventDefault();
		e.stopPropagation();
		inputRef.current?.scrollIntoView({
			behavior: "smooth",
			block: "nearest",
		});
		inputRef.current?.focus();
	};

	useEffect(() => {
		table.getColumn("name")?.setFilterValue(
			tags.map((tag) => {
				return tag.text;
			}),
		);
	}, [table, tags]);

	return (
		<Label
			ref={ref}
			className="flex items-center border border-malibu-600 rounded-md bg-white relative w-[25vw] focus:border-malibu-700 overflow-hidden "
			{...events}
			onClick={handleLabelClick}
		>
			<span className="absolute inset-y-0 left-0 flex items-center pl-2">
				<IoSearchOutline />
			</span>
			<div className="flex ml-8 space-x-1 cursor-grab">
				{tags.map((tag) => (
					<div
						key={tag.id}
						className="flex items-center bg-blue-100 text-blue-700 rounded-md px-2 py-1 select-none whitespace-nowrap"
					>
						<h1>{tag.text}</h1>
						<button
							type="button"
							className="ml-2"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								removeTag(tag.id);
							}}
						>
							&times;
						</button>
					</div>
				))}
			</div>
			<Input
				className="placeholder:text-slate-400 flex-grow shadow-sm focus:outline-none sm:text-sm border-none py-2 pl-2 w-[10vw]"
				value={inputValue}
				ref={inputRef}
				onChange={(e) => setInputValue(e.target.value)}
				onKeyUp={handleKeyDown}
				placeholder="Filter segments..."
			/>
		</Label>
	);
}

export default MultiTagInput;
