import { useState, useEffect, useRef } from "react";
import { useReactTable } from "./hooks/useReactTable";

import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuLabel,
} from "./components/dropdownMenu";
import { CaretSortIcon, DotsHorizontalIcon } from "@radix-ui/react-icons";
import {
	type ColumnDef,
	type SortingState,
	type ColumnFiltersState,
	type VisibilityState,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	getFilteredRowModel,
	type FilterFn,
} from "@tanstack/table-core";
import { v4 as uuidv4 } from "uuid";

import React from "react";
import { toast } from "sonner";
import { flexRender } from "@tanstack/react-table";
import {
	TableHeader,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
	Table,
} from "./components/table";
import { Checkbox } from "./components/checkbox";
import { Button } from "./components/button";
import { cn } from "./lib/utils";
import {
	type NotificationItem,
	useNotificationStore,
} from "./hooks/useNotification";
import MultiTagInput from "./components/mutliTag";
import {
	fetchSegments,
	sendSegments,
	type SendSegmentsParams,
} from "./apis/api";
import {
	useMutation,
	useQuery,
	useQueryClient,
	type UseQueryResult,
} from "@tanstack/react-query";
import type { DistributionManager } from "./types/distribution";
import {
	copySegmentAudienceID,
	copySegmentAudienceIDs,
	copySegmentDetail,
	copySegmentDetails,
	copySegmentID,
	copySegmentIDs,
	copySegmentLiveRampID,
	copySegmentLiveRampIDs,
	copySegmentName,
	copySegmentNames,
} from "./utils/copySegmentInfo";
import { PaginationBar } from "./PaginationBar"

interface SegmentManagerSectionProps {
	orgId: string;
	distributionManager: DistributionManager | null;
}

export type Segment = {
	name: string;
	segmentID: string;
	liveRampID: string;
	audienceID: string;
};

const SegmentManagerSection = (props: SegmentManagerSectionProps) => {
	const { orgId, distributionManager } = props;
	const [selectedSegments, setSelectedSegments] = useState({});
	const [sorting, setSorting] = React.useState<SortingState>([]);
	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
		[],
	);
	const [columnVisibility, setColumnVisibility] =
		React.useState<VisibilityState>({});
	const queryClient = useQueryClient();
	const { addNotification } = useNotificationStore();

	const tableContainerRef = useRef<HTMLDivElement>(null);

	const { data: segments, error }: UseQueryResult<Segment[], Error> = useQuery<
		Segment[],
		Error
	>({
		queryKey: ["segments", orgId],
		queryFn: () => fetchSegments({ orgId }),
		enabled: orgId !== "",
		initialData: [],
	});

	useEffect(() => {
		if (error) {
			const notification: NotificationItem = {
				id: uuidv4(),
				message: error.message,
				header: "Failed Fetching Segments",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			};
			addNotification(notification);
			console.error("Fetching segments failed:", error);
		}
	}, [addNotification, error]);

	const filterName: FilterFn<Segment> = (row, columnId, filterValue) => {
		if (!filterValue.length) return true;

		const rowValue = row.getValue<string>(columnId)?.toLowerCase() || "";

		return filterValue.some((val: string) =>
			rowValue.includes(val.toLowerCase()),
		);
	};

	const columns: ColumnDef<Segment>[] = [
		{
			id: "select",
			header: ({ table }) => (
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && "indeterminate")
					}
					onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
					aria-label="Select all"
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => row.toggleSelected(!!value)}
					aria-label="Select row"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "name",
			header: ({ column }) => {
				return (
					<Button
						variant="ghost"
						onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					>
						Name
						<CaretSortIcon className="ml-2 h-4 w-4" />
					</Button>
				);
			},
			cell: ({ row }) => (
				<div className="font-medium select-none">{row.getValue("name")}</div>
			),
			filterFn: filterName,
		},
		{
			accessorKey: "segmentID",
			header: "Segment ID",
			cell: ({ row }) => (
				<div className="font-medium select-none">
					{row.getValue("segmentID")}
				</div>
			),
		},
		{
			accessorKey: "liveRampID",
			header: "LiveRamp ID",
			cell: ({ row }) => (
				<div className="font-medium select-none">
					{row.getValue("liveRampID")}
				</div>
			),
		},
		{
			accessorKey: "audienceID",
			header: () => <div>Audience ID</div>,
			cell: ({ row }) => {
				return (
					<div className="font-medium select-none">
						{row.getValue("audienceID")}
					</div>
				);
			},
		},
		{
			id: "actions",
			header: () => (
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button variant="ghost" className="h-8 w-8 p-0">
							<span className="sr-only">Open menu</span>
							<DotsHorizontalIcon className="h-4 w-4" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end" className="bg-white">
						<DropdownMenuLabel>Actions</DropdownMenuLabel>
						<div
							className={cn(
								"relative flex cursor-default select-none rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
								"hover:bg-blue-100 cursor-pointer text-left",
							)}
							onClick={() => copySegmentNames(selectedSegments, segments)}
							onKeyUp={() => copySegmentNames(selectedSegments, segments)}
						>
							Copy Selected Segment Name
						</div>
						<div
							className={cn(
								"relative flex cursor-default select-none rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
								"hover:bg-blue-100 cursor-pointer text-left",
							)}
							onClick={() => copySegmentIDs(selectedSegments, segments)}
							onKeyUp={() => copySegmentIDs(selectedSegments, segments)}
						>
							Copy Selected Segment ID
						</div>
						<div
							className={cn(
								"relative flex cursor-default select-none rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
								"hover:bg-blue-100 cursor-pointer text-left",
							)}
							onClick={() => copySegmentLiveRampIDs(selectedSegments, segments)}
							onKeyUp={() => copySegmentLiveRampIDs(selectedSegments, segments)}
						>
							Copy Selected LiveRamp ID
						</div>
						<div
							className={cn(
								"relative flex cursor-default select-none rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
								"hover:bg-blue-100 cursor-pointer text-left",
							)}
							onClick={() => copySegmentAudienceIDs(selectedSegments, segments)}
							onKeyUp={() => copySegmentAudienceIDs(selectedSegments, segments)}
						>
							Copy Selected Audience ID
						</div>
						<div
							className={cn(
								"relative flex cursor-default select-none rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
								"hover:bg-blue-100 cursor-pointer text-left",
							)}
							onClick={() => copySegmentDetails(selectedSegments, segments)}
							onKeyUp={() => copySegmentDetails(selectedSegments, segments)}
						>
							Copy All Selected
						</div>
					</DropdownMenuContent>
				</DropdownMenu>
			),
			enableHiding: false,
			cell: ({ row }) => {
				const segment = row.original;

				return (
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="ghost" className="h-8 w-8 p-0">
								<span className="sr-only">Open menu</span>
								<DotsHorizontalIcon className="h-4 w-4" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end" className="bg-white">
							<DropdownMenuLabel>Actions</DropdownMenuLabel>
							<div
								onClick={(e) => copySegmentName(segment.name, e)}
								onKeyUp={(e) => copySegmentName(segment.name, e)}
								className={cn(
									"relative flex cursor-default select-none rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
									"hover:bg-blue-100 cursor-pointer text-left",
								)}
							>
								Copy Segment Name
							</div>
							<div
								onClick={(e) => copySegmentID(segment.segmentID, e)}
								onKeyUp={(e) => copySegmentID(segment.segmentID, e)}
								className={cn(
									"relative flex cursor-default select-none rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
									"hover:bg-blue-100 cursor-pointer text-left",
								)}
							>
								Copy Segment ID
							</div>
							<div
								onClick={(e) => copySegmentLiveRampID(segment.liveRampID, e)}
								onKeyUp={(e) => copySegmentLiveRampID(segment.liveRampID, e)}
								className={cn(
									"relative flex cursor-default select-none rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
									"hover:bg-blue-100 cursor-pointer text-left",
								)}
							>
								Copy LiveRamp ID
							</div>
							<div
								onClick={(e) => copySegmentAudienceID(segment.liveRampID, e)}
								onKeyUp={(e) => copySegmentAudienceID(segment.liveRampID, e)}
								className={cn(
									"relative flex cursor-default select-none rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
									"hover:bg-blue-100 cursor-pointer text-left",
								)}
							>
								Copy Audience ID
							</div>
							<div
								onClick={(e) => copySegmentDetail(segment, e)}
								onKeyUp={(e) => copySegmentDetail(segment, e)}
								className={cn(
									"relative flex cursor-default select-none rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
									"hover:bg-blue-100 cursor-pointer text-left",
								)}
							>
								Copy All
							</div>
						</DropdownMenuContent>
					</DropdownMenu>
				);
			},
		},
	];

	const table = useReactTable({
		data: segments,
		columns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setSelectedSegments,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection: selectedSegments,
		},
	});

	const pageSize = table.getState().pagination.pageSize;
	const totalPages = table.getRowCount();

	// const paginationRange = usePagination({
	// 	totalPages: totalPages,
	// 	pageSize: pageSize,
	// 	siblingCount: 1,
	// 	currentPage: currentPage,
	// });

	useEffect(() => {
		const calculateRows = () => {
			const container = tableContainerRef.current; // Get the current ref to the container

			// Proceed if the container is not null
			if (container) {
				const staticElements = container.querySelectorAll(".static-height");
				const staticElementsHeight = Array.from(staticElements).reduce(
					(total, elem) => total + elem.clientHeight,
					0,
				);

				const rowHeight = container.querySelector("tr")?.clientHeight || 0;

				const availableHeight =
					(container.clientHeight - staticElementsHeight) * 0.6;
				const numRows = Math.floor(availableHeight / rowHeight);
				table.setPageSize(numRows);
			}
		};

		// Calculate rows on mount and resize
		calculateRows();
		window.addEventListener("resize", calculateRows);

		// Clean up event listener on unmount
		return () => {
			window.removeEventListener("resize", calculateRows);
		};
	}, [table]);

	const mutation = useMutation<boolean, Error, SendSegmentsParams>({
		mutationFn: sendSegments,
		onSuccess: () => {
			setSelectedSegments({});
			queryClient.invalidateQueries({
				queryKey: ["distributedSegments", distributionManager?.id, orgId],
			});
		},
		onError: (error) => {
			const notification: NotificationItem = {
				id: uuidv4(),
				message: error.message,
				header: "Failed Sending Segments",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			};
			addNotification(notification);
			console.error("Sending segments failed:", error);
		},
	});

	const handleSendSegments = async () => {
		if (
			orgId === "" ||
			!distributionManager ||
			Object.keys(selectedSegments).length === 0
		)
			return;

		const segmentIDs: string[] = [];
		for (const key of Object.keys(selectedSegments)) {
			const num = Number(key);
			const segment =
				!Number.isNaN(num) && num < segments.length
					? segments[Number(key)]
					: null;
			if (segment) {
				segmentIDs.push(segment.segmentID);
			}
		}

		return mutation.mutateAsync({
			orgId,
			distributionManagerId: distributionManager.id,
			segmentIDs,
		});
	};

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		toast.promise(handleSendSegments(), {
			loading: "Loading...",
			success: () => {
				addNotification({
					id: uuidv4(),
					message: "Segments have been sent",
					header: "Successfully Sent Segments",
					notification_type: "success",
					timestamp: new Date().toISOString(),
				});
				return "Segments have been sent";
			},
			error: (error) => {
				addNotification({
					id: uuidv4(),
					message:
						error instanceof Error
							? error.message
							: "There was an error trying to send the segments.",
					header: "Failed Sending Segments",
					notification_type: "error",
					timestamp: new Date().toISOString(),
				});
				return `An error occurred while trying to send the segments: ${error}`;
			},
		});
	};

	return (
		<div className="flex flex-col bg-section-color shadow-lg rounded-lg p-6 w-full mt-8">
			{/**/}
			{/* Title and Search/Add */}
			<div>
				<div className="flex justify-between items-center h-full">
					<h2 className="text-3xl font-extrabold text-dark-text">Segment</h2>

					<MultiTagInput table={table} />
				</div>
			</div>

			{/* Segments Table */}
			<div className="flex-grow" ref={tableContainerRef}>
				<div className="w-full p-2">
					<div className="flex items-center py-2" />
					<div className="border bg-white rounded-lg">
						<Table>
							<TableHeader>
								{table.getHeaderGroups().map((headerGroup) => (
									<TableRow key={headerGroup.id}>
										{headerGroup.headers.map((header) => {
											return (
												<TableHead key={header.id}>
													{header.isPlaceholder
														? null
														: flexRender(
																header.column.columnDef.header,
																header.getContext(),
															)}
												</TableHead>
											);
										})}
									</TableRow>
								))}
							</TableHeader>
							<TableBody>
								{table.getRowModel().rows?.length ? (
									table.getRowModel().rows.map((row) => (
										<TableRow
											key={row.id}
											data-state={row.getIsSelected() && "selected"}
											className="hover:bg-malibu-100 cursor-pointer"
											onClick={() => {
												row.toggleSelected(!row.getIsSelected());
											}}
										>
											{row.getVisibleCells().map((cell) => (
												<TableCell key={cell.id}>
													{flexRender(
														cell.column.columnDef.cell,
														cell.getContext(),
													)}
												</TableCell>
											))}
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell
											colSpan={columns.length}
											className="h-24 text-center"
										>
											No segments.
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</div>
				</div>
			</div>

			{/* Pagination */}
			<div className="flex items-center justify-center space-x-2 py-2">
				<div className="text-sm text-muted-foreground ml-auto">
					{table.getFilteredSelectedRowModel().rows.length} of{" "}
					{table.getFilteredRowModel().rows.length} row(s) selected.
				</div>
				<PaginationBar
					totalItems={totalPages}
					itemsPerPage={pageSize}
					onPageChange={table.setPageIndex}
				/>
				<Button
					className={cn(
						"bg-button-color text-dark-text hover:bg-blue-400 font-semi-bold py-2 px-4 rounded ml-2 bg-button-color",
					)}
					disabled={
						!distributionManager || Object.keys(selectedSegments).length === 0
					}
					onClick={handleSubmit}
				>
					Distribute
				</Button>
			</div>
		</div>
	);
};

export default SegmentManagerSection;
