import { useEffect, useState } from "react";
import {  Toaster } from "sonner";
import { useAuthStore } from "./hooks/useAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Dashboard from "./Dashboard";
import exchangeCodeForTokens from "./apis/exchangeCodeForTokens";
import LoginPage from "./LoginPage";
import { Loader2 } from "lucide-react";
const queryClient = new QueryClient({});

export function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<MainPage />
		</QueryClientProvider>
	);
}


const LoginLoadingState: React.FC = () => (
	<div className="flex items-center justify-center h-screen bg-gray-100">
		<div className="bg-white p-8 rounded-lg shadow-md flex flex-col items-center">
			<Loader2 className="h-12 w-12 text-blue-500 animate-spin mb-4" />
			<h2 className="text-2xl font-semibold text-gray-800 mb-2">Logging In</h2>
			<p className="text-gray-600">
				Please wait while we securely sign you in...
			</p>
		</div>
	</div>
);

function MainPage() {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const accessToken = useAuthStore((state) => state.accessToken);
	const fetchWithAuth = useAuthStore((state) => state.fetchWithAuth);
	const clearAccessToken = useAuthStore((state) => state.clearAccessToken);
	const delayDuration = 2000;


	useEffect(() => {
		async function verifyToken() {
			try {
				if (accessToken === "") {
					return;
				}
				const response = await fetchWithAuth(
					`${process.env.REACT_APP_API_URL}/api/v1/verify`,
					{ method: "POST" },
				);
				setIsAuthenticated(response.ok);
				if (!response.ok) clearAccessToken();
			} catch (error) {
				setIsAuthenticated(false);
				clearAccessToken();
			} finally {
				setIsInitialLoad(false);
			}
		}

		verifyToken();
	}, [clearAccessToken, fetchWithAuth, accessToken]);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get("code");

		if (code) {
			const handleCodeExchange = async () => {
				setIsLoading(true);
				try {
					await new Promise((resolve) => setTimeout(resolve, delayDuration));
					await exchangeCodeForTokens(code);
					setIsAuthenticated(true);
					window.history.replaceState(
						{},
						document.title,
						window.location.pathname,
					);
				} catch (error) {
					setIsAuthenticated(false);
					console.error("Authentication error:", error);
				} finally {
					setIsLoading(false);
				}
			};

			handleCodeExchange();
		}
	}, []);

	if (isInitialLoad) {
		return null;
	}

	if (isLoading) {
		return <LoginLoadingState />;
	}

	return (
		<div>
			<Toaster
				position="bottom-right"
				theme="light"
				richColors
				toastOptions={{
					classNames: {
						toast: "bg-white",
						title: "text-black",
						description: "text-black",
						actionButton: "text-black",
						cancelButton: "text-black",
						closeButton: "text-black",
						error: "bg-red-400",
						success: "text-green-400",
						warning: "text-yellow-400",
						info: "bg-blue-400",
					},
				}}
			/>
			{isAuthenticated ? <Dashboard /> : <LoginPage />}
		</div>
	);
}

export default App;
