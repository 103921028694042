import { toast } from 'sonner';
import type { Segment } from '../SegmentManager';

export const copySegmentNames = (
  selectedSegments: object,
  segments: Segment[]
) => {
  const segmentJSON = [];
  for (const key of Object.keys(selectedSegments)) {
    const num = Number(key);
    const segment =
      !Number.isNaN(num) && num < segments.length
        ? segments[Number(key)]
        : null;
    if (segment) {
      segmentJSON.push({
        name: segment.name,
      });
    }
  }
  navigator.clipboard.writeText(JSON.stringify(segmentJSON, null, '\t'));
  toast.success('Copied Segment Names');
};

export const copySegmentName = (name: string, event: React.SyntheticEvent) => {
  event.stopPropagation();
  navigator.clipboard.writeText(name);
  toast.success('Copied Segment Name');
};

export const copySegmentIDs = (
  selectedSegments: object,
  segments: Segment[]
) => {
  const segmentJSON = [];
  for (const key of Object.keys(selectedSegments)) {
    const num = Number(key);
    const segment =
      !Number.isNaN(num) && num < segments.length
        ? segments[Number(key)]
        : null;
    if (segment) {
      segmentJSON.push({
        name: segment.name,
        segmentID: segment.segmentID,
      });
    }
  }
  navigator.clipboard.writeText(JSON.stringify(segmentJSON, null, '\t'));
  toast.success('Copied Segment IDs');
};

export const copySegmentID = (
  segmentID: string,
  event: React.SyntheticEvent
) => {
  event.stopPropagation();
  navigator.clipboard.writeText(segmentID);
  toast.success('Copied Segment ID');
};

export const copySegmentLiveRampIDs = (
  selectedSegments: object,
  segments: Segment[]
) => {
  const segmentJSON = [];
  for (const key of Object.keys(selectedSegments)) {
    const num = Number(key);
    const segment =
      !Number.isNaN(num) && num < segments.length
        ? segments[Number(key)]
        : null;
    if (segment) {
      segmentJSON.push({
        name: segment.name,
        liveRampID: segment.liveRampID,
      });
    }
  }
  navigator.clipboard.writeText(JSON.stringify(segmentJSON, null, '\t'));
  toast.success('Copied LiveRamp IDs');
};

export const copySegmentLiveRampID = (
  liveRampID: string,
  event: React.SyntheticEvent
) => {
  event.stopPropagation();
  navigator.clipboard.writeText(liveRampID);
  toast.success('Copied LiveRamp ID');
};

export const copySegmentAudienceIDs = (
  selectedSegments: object,
  segments: Segment[]
) => {
  const segmentJSON = [];
  for (const key of Object.keys(selectedSegments)) {
    const num = Number(key);
    const segment =
      !Number.isNaN(num) && num < segments.length
        ? segments[Number(key)]
        : null;
    if (segment) {
      segmentJSON.push({
        name: segment.name,
        audienceID: segment.audienceID,
      });
    }
  }
  navigator.clipboard.writeText(JSON.stringify(segmentJSON, null, '\t'));
  toast.success('Copied Audience IDs');
};

export const copySegmentAudienceID = (
  audienceID: string,
  event: React.SyntheticEvent
) => {
  event.stopPropagation();
  navigator.clipboard.writeText(audienceID);
  toast.success('Copied Audience ID');
};

export const copySegmentDetails = (
  selectedSegments: object,
  segments: Segment[]
) => {
  const segmentJSON = [];
  for (const key of Object.keys(selectedSegments)) {
    const num = Number(key);
    const segment =
      !Number.isNaN(num) && num < segments.length
        ? segments[Number(key)]
        : null;
    if (segment) {
      segmentJSON.push({
        name: segment.name,
        segmentID: segment.segmentID,
        liveRampID: segment.liveRampID,
        audienceID: segment.audienceID,
      });
    }
  }
  navigator.clipboard.writeText(JSON.stringify(segmentJSON, null, '\t'));
  toast.success('Copied all segments');
};

export const copySegmentDetail = (
  segment: Segment,
  event: React.SyntheticEvent
) => {
  event.stopPropagation();
  const selectedSegment = {
    name: segment.name,
    segmentID: segment.segmentID,
    liveRampID: segment.liveRampID,
    audienceID: segment.audienceID,
  };

  navigator.clipboard.writeText(JSON.stringify(selectedSegment, null, '\t'));
  toast.success('Copied all');
};
