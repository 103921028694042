// Utility function for chunking an array
export function chunkArray<T>(array: T[], size: number): T[][] {
	return array.reduce((resultArray, item, index) => {
		const chunkIndex = Math.floor(index / size);
		if (!resultArray[chunkIndex]) {
			resultArray[chunkIndex] = []; // start a new chunk
		}
		resultArray[chunkIndex].push(item);
		return resultArray;
	}, [] as T[][]);
}
