export function formatDate(date: Date | undefined): string | null {
	if (!date) return null;
	const month = date.getMonth() + 1; // getMonth() is zero-indexed
	const day = date.getDate();
	const year = date.getFullYear();

	// Pad the month and day with a zero if they are less than 10
	const formattedMonth = month < 10 ? `0${month}` : month;
	const formattedDay = day < 10 ? `0${day}` : day;

	// Construct the final string
	const formattedDate = `${formattedMonth}-${formattedDay}-${year}`;
	return formattedDate;
}

export function parseFormattedDate(dateString: string): Date | null {
	if (!dateString) return null;

	const [month, day, year] = dateString.split("-").map(Number);

	// Validate the input
	if (Number.isNaN(month) || Number.isNaN(day) || Number.isNaN(year)) {
		return null;
	}

	const date = new Date(year, month - 1, day);

	// Validate that the date is valid
	if (
		date.getFullYear() !== year ||
		date.getMonth() !== month - 1 ||
		date.getDate() !== day
	) {
		return null;
	}

	return date;
}

export function isDateExpired(dateString: string): boolean {
	const parsedDate = parseFormattedDate(dateString);

	if (!parsedDate) {
		return true;
	}

	const currentDate = new Date();

	currentDate.setHours(0, 0, 0, 0);
	parsedDate.setHours(0, 0, 0, 0);

	return parsedDate < currentDate;
}