import type React from "react";
import {
	PaginationItem,
	PaginationLink,
	Pagination,
	PaginationContent,
	PaginationPrevious,
	PaginationNext,
	PaginationEllipsis,
} from "./components/pagination";
import { usePagination } from "./hooks/usePagination";

interface PaginationComponentProps {
	totalItems: number;
	itemsPerPage: number;
	onPageChange?: (page: number) => void;
	className?: string;
	siblingCount?: number;
}

export const PaginationBar: React.FC<PaginationComponentProps> = ({
	totalItems,
	itemsPerPage,
	onPageChange,
	className,
	siblingCount = 1,
}) => {
	const {
		currentPage,
		pageNumbers,
		goToPage,
		goToNextPage,
		goToPreviousPage,
		canGoToNextPage,
		canGoToPreviousPage,
	} = usePagination({ totalItems, itemsPerPage, siblingCount });

	const handlePageChange = (page: number) => {
		goToPage(page);
		onPageChange?.(page);
	};


	return (
		<Pagination className={className}>
			<PaginationContent>
				<PaginationItem>
					<PaginationPrevious
						href="#"
						onClick={goToPreviousPage}
						className={
							!canGoToPreviousPage
								? "text-gray-300 cursor-not-allowed"
								: "cursor-pointer"
						}
						size={"default"}
					/>
				</PaginationItem>
				{pageNumbers.map((pageNumber, index) => (
					<PaginationItem key={`pagination_${pageNumber}_${index}`}>
						{pageNumber === "..." ? (
							<PaginationEllipsis />
						) : (
							<PaginationLink
								onClick={() => handlePageChange(pageNumber as number)}
								isActive={pageNumber === currentPage}
								className="cursor-pointer bg-white border-malibu-600"
								size={"default"}
							>
								{pageNumber}
							</PaginationLink>
						)}
					</PaginationItem>
				))}
				<PaginationItem>
					<PaginationNext
						href="#"
						onClick={goToNextPage}
						className={
							!canGoToNextPage
								? "text-gray-300 cursor-not-allowed"
								: "cursor-pointer"
						}
						size={"default"}
					/>
				</PaginationItem>
			</PaginationContent>
		</Pagination>
	);
};
