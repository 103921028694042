import { useEffect, useState } from "react";
import { FaBell } from "react-icons/fa";
import { Popover, PopoverContent, PopoverTrigger } from "./components/popover";
import { useNotificationStore } from "./hooks/useNotification";
import { MdCheck, MdError, MdInfo } from "react-icons/md";
import React from "react";

const Header = () => {
	return (
		<div className="w-full h-[5vh] flex items-center justify-end px-6 mt-4">
			{/* Navigation or additional content could go here */}
			<Notifications />
			<nav>{/* Navigation items or buttons */}</nav>
		</div>
	);
};

const Notifications = React.memo(() => {
	const {
		notifications,
		removeNotification,
		clearNotifications,
		readNotification,
	} = useNotificationStore();

	const [fadingOutIds, setFadingOutIds] = useState(new Set());
	const [unreadCount, setUnreadCount] = useState(0);

	// This function handles the fade-out and removal of notifications
	const handleRemoveNotification = (id: string) => {
		setFadingOutIds(new Set(fadingOutIds).add(id));
		setTimeout(() => {
			removeNotification(id);
			setFadingOutIds((prevIds) => {
				const updatedIds = new Set(prevIds);
				updatedIds.delete(id);
				return updatedIds;
			});
		}, 300);
	};

	useEffect(() => {
		const count = notifications.reduce(
			(acc, notification) => acc + (notification.isRead ? 1 : 0),
			0,
		);
		setUnreadCount(count);
	}, [notifications]);

	console.log(notifications);
	return (
		<Popover>
			<PopoverTrigger asChild>
				<div className="cursor-pointer relative">
					<FaBell className="h-6 w-6" />
					{unreadCount > 0 && (
						<span
							className={`absolute -top-3 -right-2 flex items-center justify-center
                  bg-blue-500 text-white text-xs font-semibold
                  h-5 w-5 rounded-full border-2 border-white
                  ${unreadCount >= 100 ? "text-xxs p-0" : "p-1"}`}
						>
							{unreadCount < 100 ? unreadCount : "99+"}
						</span>
					)}
				</div>
			</PopoverTrigger>
			<PopoverContent
				onCloseAutoFocus={() => readNotification()}
				className="rounded-lg border bg-white text-black shadow-md w-[25vw] absolute right-0 mt-2 z-50 p-0 -right-3"
			>
				<div className="bg-gray-200 flex items-center justify-between bg-section-color">
					<div className="px-4 py-2 font-semibold">Notifications</div>
					{notifications.length > 0 && (
						<div
							className="px-4 py-2 cursor-pointer font-semibold"
							onClick={() => {
								clearNotifications();
							}}
							onKeyUp={() => {
								clearNotifications();
							}}
						>
							{"Clear"}
						</div>
					)}
				</div>

				<div className="overflow-y-auto max-h-96">
					{notifications.length > 0 ? (
						notifications.map((item) => (
							<div
								className={`flex items-center py-3 px-3 border-b cursor-pointer hover:bg-blue-100 select-none ${
									fadingOutIds.has(item.id) ? "fade-out" : ""
								}`}
								key={item.id}
								onClick={(event) => {
									event.stopPropagation();
									handleRemoveNotification(item.id);
								}}
								onKeyUp={(event) => {
									event.stopPropagation();
									handleRemoveNotification(item.id);
								}}
							>
								<div className="mr-4">
									{(() => {
										switch (item.notification_type) {
											case "error":
												return <MdError className="h-9 w-9 text-red-600" />;
											case "success":
												return <MdCheck className="h-9 w-9 text-green-500" />;
											default:
												return <MdInfo className="h-9 w-9 text-blue-500" />;
										}
									})()}
								</div>
								<div className="flex-1 min-w-0">
									<h4 className="font-medium leading-none">{item.header}</h4>
									<p className="text-sm text-muted-foreground mt-1">
										{item.message}
									</p>
									<p className="text-xs text-gray-500 mt-1">
										{new Intl.DateTimeFormat("default", {
											month: "short",
											day: "numeric",
											hour: "2-digit",
											minute: "2-digit",
										}).format(new Date(item.timestamp))}
									</p>
								</div>
								{item.isRead ? (
									<div className="relative flex justify-center items-center h-3 w-3 mx-2">
										<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75" />
										<span className="relative inline-flex rounded-full h-2.5 w-2.5 bg-sky-500" />
									</div>
								) : null}
							</div>
						))
					) : (
						<div className="py-2 px-4">No Notifications</div>
					)}
				</div>
			</PopoverContent>
		</Popover>
	);
});

export default Header;
