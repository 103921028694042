import { type ReactNode, useEffect, useMemo, useState } from "react";
import {
	Credenza,
	CredenzaTrigger,
	CredenzaContent,
	CredenzaHeader,
	CredenzaTitle,
	CredenzaBody,
	CredenzaFooter,
} from "./components/credenza";
import { IoInformationCircleOutline } from "react-icons/io5";
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
} from "@radix-ui/react-popover";
import { AiFillCloseCircle } from "react-icons/ai";
import { CalendarIcon } from "lucide-react";
import { Button } from "./components/button";
import { cn } from "./lib/utils";
import React from "react";
import { Input } from "./components/input";
import { Label } from "./components/label";
import { Calendar } from "./components/calendar";
import { format } from "date-fns";
import {
	TooltipProvider,
	Tooltip,
	TooltipTrigger,
	TooltipContent,
} from "@radix-ui/react-tooltip";
import type { ComboItem } from "./components/combobox";
import { MultiSelect } from "./components/multiSelect";
import { toast } from "sonner";
import { Checkbox } from "./components/checkbox";
import { Skeleton } from "./components/skeleton";
import { v4 as uuidv4 } from "uuid";
import { useNotificationStore } from "./hooks/useNotification";
import { chunkArray } from "./utils/chunkArray";
import {
	useMutation,
	useQuery,
	useQueryClient,
	type UseQueryResult,
} from "@tanstack/react-query";
import {
	type DistributionManagerResp,
	fetchDistributionManager,
	updateDistributionManager,
	type UpdateDistributionManagerParams,
} from "./apis/api";
import type {
	Destinations,
	DistributionManager,
	Property,
} from "./types/distribution";

interface IntegrationModalProps {
	orgId: string;
	triggerReact?: ReactNode;
	destination: Destinations;
	distributionManager: DistributionManager;
	open: boolean | undefined;
	setOpen: ((open: boolean) => void) | undefined;
}

const IntegrationEditModal = (props: IntegrationModalProps) => {
	const {
		orgId,
		triggerReact,
		destination,
		distributionManager,
		open,
		setOpen,
	} = props;
	const [expireAtDate, setExpireAtDate] = React.useState<Date | undefined>(
		undefined,
	);
	const [requiredProperties, setRequiredProperties] = useState<Property[]>([]);
	const [deviceTypes, setDeviceTypes] = useState<ComboItem[]>([]);
	const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<string[]>([]);
	const [enableEdit, setEnableEdit] = useState<boolean>(false);
	const [distributionManagerName, setDistributionManagerName] =
		useState<string>("");
	const queryClient = useQueryClient();

	const { addNotification } = useNotificationStore();

	// Function to handle form changes and gather data
	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		index: number,
	) => {
		const { value } = event.target;
		setRequiredProperties((prevProperties) => {
			if (index >= 0 && index < prevProperties.length) {
				const updatedProperties = [...prevProperties];
				updatedProperties[index] = {
					...updatedProperties[index],
					value: value,
				};
				return updatedProperties;
			}
			return prevProperties;
		});
	};

	const mutation = useMutation<string, Error, UpdateDistributionManagerParams>({
		mutationFn: updateDistributionManager,
		onSuccess: (data) => {
			queryClient.invalidateQueries({
				queryKey: ["distributionManagers", orgId],
			});
			queryClient.invalidateQueries({
				queryKey: ["distributionManager", orgId, distributionManager.id],
			});
			setEnableEdit(false);
			addNotification({
				id: uuidv4(),
				message: `${data} has been updated`,
				header: "Successfully Updated Distribution Manager",
				notification_type: "success",
				timestamp: new Date().toISOString(),
			});
		},
		onError: (error) => {
			addNotification({
				id: uuidv4(),
				message: error.message,
				header: "Failed Updating Distribution Manager",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			});
			console.error("Updating distribution manager failed:", error);
		},
	});

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		toast.promise(
			mutation.mutateAsync({
				orgId,
				distributionManagerId: distributionManager.id,
				distributionManagerName,
				integrationConnectionID: distributionManager.integrationConnectionID,
				expireAtDate,
				selectedDeviceTypes,
				requiredProperties,
			}),
			{
				loading: "Loading...",
				success: (data) => `${data} has been updated`,
				error: (error) =>
					`An error occurred while trying to update the Distribution Manager: ${error.message}`,
			},
		);
	};

	const {
		data,
		error,
		isFetching,
	}: UseQueryResult<DistributionManagerResp, Error> = useQuery({
		queryKey: ["distributionManager", orgId, distributionManager.id],
		queryFn: () =>
			fetchDistributionManager({
				orgId,
				distributionManagerId: distributionManager.id,
			}),
		enabled: orgId !== "" && distributionManager.id !== "",
	});

	useEffect(() => {
		if (error) {
			addNotification({
				id: uuidv4(),
				message: error.message,
				header: "Failed Fetching Distribution Manager",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			});
			console.error("Fetching distribution manager failed:", error);
		}
	}, [addNotification, error]);

	useEffect(() => {
		if (data) {
			setDistributionManagerName(data.name);
			setRequiredProperties(data.integrationConnection.properties);
			setDeviceTypes(
				data.integrationConnection.deviceTypes.map((deviceType) => {
					return {
						value: deviceType.name,
						label: deviceType.name,
					};
				}),
			);
			setSelectedDeviceTypes(
				data.integrationConnection.deviceTypes
					.filter((deviceType) => deviceType.selected)
					.map((selectedTypes) => selectedTypes.name),
			);
		}
	}, [data]);

	const requiredPropertiesChunks = useMemo(
		() => chunkArray(requiredProperties.slice(1), 2),
		[requiredProperties],
	);

	return (
		<Credenza open={open} onOpenChange={setOpen}>
			<CredenzaTrigger asChild>{triggerReact}</CredenzaTrigger>
			<CredenzaContent className="bg-gray-section">
				<CredenzaHeader>
					<CredenzaTitle className="text-3xl font-extrabold">
						Integration
					</CredenzaTitle>
				</CredenzaHeader>
				{isFetching ? (
					<div>
						<div className="flex flex-col gap-4 justify-center items-center">
							<Skeleton className="w-3/4 h-8 rounded-md bg-gray-300 flex" />
							<Skeleton className="w-3/4 h-8 rounded-md bg-gray-300 flex" />
						</div>
						<div className="flex items-center space-x-2 mt-4">
							<Skeleton
								id="editPage"
								className="h-4 w-4 bg-gray-300 rounded-sm"
							/>
							<Skeleton className="h-3 w-32 bg-gray-300 rounded-sm" />
						</div>
						<div className="mt-4">
							<div className="flex flex-row gap-4 justify-start items-center">
								<div className="w-24/50 block">
									<Skeleton className="h-4 w-32 bg-gray-300 rounded-sm mb-2" />
									<Skeleton className="h-8 rounded-md bg-gray-300 flex" />
								</div>
								<div className="w-24/50">
									<Skeleton className="h-4 w-32 bg-gray-300 rounded-sm mb-2" />
									<Skeleton className="h-8 rounded-md bg-gray-300 flex" />
								</div>
							</div>
						</div>
						<div className="mt-4">
							<div className="flex flex-row gap-4 justify-start items-center">
								<div className="w-24/50 block">
									<Skeleton className="h-4 w-32 bg-gray-300 rounded-sm mb-2" />
									<Skeleton className="h-8 rounded-md bg-gray-300 flex" />
								</div>
								<div className="w-24/50">
									<Skeleton className="h-4 w-32 bg-gray-300 rounded-sm mb-2" />
									<Skeleton className="h-8 rounded-md bg-gray-300 flex" />
								</div>
							</div>
						</div>
						<div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
							<Skeleton className="h-9 w-16 bg-gray-300 mt-4 mr-1" />
						</div>
					</div>
				) : (
					<form onSubmit={handleSubmit}>
						<CredenzaBody>
							{/*Destination/Integration Row*/}
							<div className="flex flex-col gap-4 justify-center items-center">
								<Input
									type={"Distribution"}
									className="w-3/4 border-border-color px-4 py-2 border-b border-gray-200 outline-none select-none"
									value={destination.name}
									disabled
								/>
								<Input
									type={"Integration"}
									className="w-3/4 border-border-color  px-4 py-2 border-b border-gray-200 outline-none focus:ring-2 focus:ring-blue-500 select-none"
									value={data?.integrationConnection.name}
									disabled
								/>
							</div>
							<div className="flex items-center space-x-2 mt-4">
								<Checkbox
									id="editPage"
									onCheckedChange={() => setEnableEdit(!enableEdit)}
									checked={enableEdit}
								/>
								<label
									htmlFor="editPage"
									className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
								>
									Edit Distribution Manager
								</label>
							</div>
							{requiredProperties.length > 0 && (
								<>
									<div className="max-h-96 overflow-y-auto scroll-smooth focus:scroll-auto mt-4">
										<div className="flex flex-row gap-4 justify-start items-center">
											{/*Integration Name*/}
											<Label className="block w-full text-md font-medium text-gray-700 w-24/50">
												Integration Name
												<Input
													disabled={!enableEdit}
													required
													className="border-border-color"
													placeholder="Enter Integration Name..."
													onChange={(
														event: React.ChangeEvent<HTMLInputElement>,
													) => {
														setDistributionManagerName(event.target.value);
													}}
													value={distributionManagerName}
													name="integration name"
												/>
											</Label>
											{/*Expire At*/}
											<Label className="block text-md font-medium text-gray-700 w-24/50">
												Expire-At (optional)
												<Popover>
													<PopoverTrigger asChild>
														<Button
															disabled={!enableEdit}
															variant={"outline"}
															className={cn(
																"flex items-center justify-between text-left font-normal w-full border-border-color pr-1.5",
																!expireAtDate && "text-muted-foreground",
															)}
														>
															<div className="flex justify-between items-center w-full">
																<div className="flex items-center">
																	<CalendarIcon className="mr-2 h-3 w-3" />
																	{expireAtDate ? (
																		<span>{format(expireAtDate, "PPP")}</span>
																	) : (
																		<span>Pick a date</span>
																	)}
																</div>
																<button
																	onClick={(event) => {
																		event.stopPropagation();
																		setExpireAtDate(undefined);
																	}}
																	className="bg-transparent p-0 ml-4"
																	type="button"
																>
																	<AiFillCloseCircle className="w-4 h-4" />
																</button>
															</div>
														</Button>
													</PopoverTrigger>
													<PopoverContent className="w-auto p-0" align="start">
														<Calendar
															mode="single"
															selected={expireAtDate}
															onSelect={(selectedDate: Date | undefined) => {
																setExpireAtDate(selectedDate);
															}}
															initialFocus
															className="bg-white"
														/>
													</PopoverContent>
												</Popover>
											</Label>
										</div>

										<div className="flex flex-row gap-4 justify-start items-center pt-4">
											{/*Device Type*/}
											<Label className="block w-full text-md font-medium text-gray-700 w-24/50">
												Device Type
												<TooltipProvider>
													<Tooltip>
														<TooltipTrigger type="button">
															<IoInformationCircleOutline className="text-border-color ml-2" />
														</TooltipTrigger>
														<TooltipContent>
															<p className="bg-white rounded-md p-2 text-dark-text shadow-lg border-border-color">
																if available, <strong>RAMP_ID</strong> is
																usually the prefered option
															</p>
														</TooltipContent>
													</Tooltip>
												</TooltipProvider>
												<MultiSelect
													disabled={!enableEdit}
													options={deviceTypes}
													type={"Device Type"}
													className="w-full border-border-color"
													selected={selectedDeviceTypes}
													onChange={setSelectedDeviceTypes}
													selectAll={false}
												/>
											</Label>
											{/**/}
											<Label className="block w-full text-md font-medium text-gray-700 w-24/50">
												{requiredProperties[0].name}
												{requiredProperties[0].description !== "" && (
													<TooltipProvider>
														<Tooltip>
															<TooltipTrigger type="button">
																<IoInformationCircleOutline className="text-border-color ml-2" />
															</TooltipTrigger>
															<TooltipContent>
																<p className="bg-white rounded-md p-2 text-dark-text shadow-lg border-border-color">
																	{requiredProperties[0].description}
																</p>
															</TooltipContent>
														</Tooltip>
													</TooltipProvider>
												)}
												<Input
													disabled={!enableEdit}
													required
													className="border-border-color"
													placeholder={`Enter ${requiredProperties[0].name} Name...`}
													name={requiredProperties[0].name}
													value={requiredProperties[0].value}
													onChange={(e) => handleInputChange(e, 0)}
												/>
											</Label>
										</div>
										{requiredProperties.length > 1 &&
											requiredPropertiesChunks.map((chunk, index) =>
												chunk.map((property, propertyIndex) => (
													<div
														key={`${index}${property.name}`}
														className="flex flex-row gap-4 justify-start items-center pt-4"
													>
														<Label className="block w-full text-md font-medium text-gray-700 w-24/50">
															{property.name}
															{property.description !== "" && (
																<TooltipProvider>
																	<Tooltip>
																		<TooltipTrigger type="button">
																			<IoInformationCircleOutline className="text-border-color ml-2" />
																		</TooltipTrigger>
																		<TooltipContent>
																			<p className="bg-white rounded-md p-2 text-dark-text shadow-lg border-border-color">
																				{property.description}
																			</p>
																		</TooltipContent>
																	</Tooltip>
																</TooltipProvider>
															)}
															<Input
																disabled={!enableEdit}
																required
																placeholder={`Enter ${property.name}...`}
																className="border-border-color"
																name={property.name}
																value={property.value}
																onChange={(e) =>
																	handleInputChange(
																		e,
																		index * 2 + propertyIndex + 1,
																	)
																}
															/>
														</Label>
													</div>
												)),
											)}
									</div>
								</>
							)}
						</CredenzaBody>
						<CredenzaFooter>
							<Button
								className="font-semi-bold py-2 px-4 rounded text-dark-text mt-4 bg-button-color text-dark-text hover:bg-blue-400"
								disabled={
									requiredProperties.length === 0 ||
									distributionManagerName === "" ||
									selectedDeviceTypes.length === 0 ||
									!enableEdit
								}
								type="submit"
							>
								Edit
							</Button>
						</CredenzaFooter>
					</form>
				)}
			</CredenzaContent>
		</Credenza>
	);
};

export default IntegrationEditModal;
