import { useEffect, useMemo, useState } from "react";
import {
	Credenza,
	CredenzaTrigger,
	CredenzaContent,
	CredenzaHeader,
	CredenzaTitle,
	CredenzaBody,
	CredenzaFooter,
} from "./components/credenza";
import { IoInformationCircleOutline } from "react-icons/io5";
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
} from "@radix-ui/react-popover";
import { AiFillCloseCircle } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import { CalendarIcon } from "lucide-react";
import { Button } from "./components/button";
import { cn } from "./lib/utils";
import React from "react";
import { Input } from "./components/input";
import { Label } from "./components/label";
import { Calendar } from "./components/calendar";
import { format } from "date-fns";
import {
	TooltipProvider,
	Tooltip,
	TooltipTrigger,
	TooltipContent,
} from "@radix-ui/react-tooltip";
import { type ComboItem, Combobox } from "./components/combobox";
import { MultiSelect } from "./components/multiSelect";
import { toast } from "sonner";
import { v4 as uuidv4 } from "uuid";
import {
	type NotificationItem,
	useNotificationStore,
} from "./hooks/useNotification";
import {
	useMutation,
	useQuery,
	useQueryClient,
	type UseQueryResult,
} from "@tanstack/react-query";
import {
	createDistributionManager,
	type CreateDistributionManagerParams,
	fetchIntegrationTypes,
	fetchRequiredProperties,
} from "./apis/api";
import { chunkArray } from "./utils/chunkArray";
import type { Destinations, Property } from "./types/distribution";

interface IntegrationModalProps {
	orgId: string;
	destinations: Map<string, Destinations>;
}

const IntegrationModal = (props: IntegrationModalProps) => {
	const { orgId, destinations } = props;
	const [expireAtDate, setExpireAtDate] = React.useState<Date | undefined>(
		new Date(),
	);
	const queryClient = useQueryClient();
	const [destinationId, setDestinationId] = useState<string>("");
	const [integrationId, setIntegrationId] = useState<string>("");
	const [properties, setProperties] = React.useState<Property[]>([]);
	const [deviceTypes, setDeviceTypes] = useState<ComboItem[]>([]);
	const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<string[]>([]);
	const [distributionManagerName, setDistributionManagerName] =
		useState<string>("");
	const { addNotification } = useNotificationStore();

	// Function to handle form changes and gather data
	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		index: number,
	) => {
		const { value } = event.target;
		setProperties((prevProperties) => {
			if (index >= 0 && prevProperties && index < prevProperties.length) {
				const updatedProperties = [...prevProperties];
				updatedProperties[index] = {
					...updatedProperties[index],
					value: value,
				};
				return updatedProperties;
			}
			return prevProperties;
		});
	};

	const mutation = useMutation<string, Error, CreateDistributionManagerParams>({
		mutationFn: createDistributionManager,
		onSuccess: (data) => {
			setSelectedDeviceTypes([]);
			setExpireAtDate(undefined);
			setProperties(properties.map((property) => ({ ...property, value: "" })));
			setDistributionManagerName("");
			queryClient.invalidateQueries({
				queryKey: ["distributionManagers", orgId],
			});
			addNotification({
				id: uuidv4(),
				message: `${data} has been added`,
				header: "Successfully Created Distribution Manager",
				notification_type: "success",
				timestamp: new Date().toISOString(),
			});
		},
		onError: (error) => {
			addNotification({
				id: uuidv4(),
				message: error.message,
				header: "Failed Creating Distribution Manager",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			});
			console.error("Creating distribution manager failed:", error);
		},
	});

	// Function to handle form submission
	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		toast.promise(
			mutation.mutateAsync({
				orgId,
				distributionManagerName,
				destinationId,
				integrationId,
				selectedDeviceTypes,
				properties,
				expireAtDate,
			}),
			{
				loading: "Loading...",
				success: (data) => `${data} has been added`,
				error: (error) =>
					`An error occurred while trying to create the Distribution Manager: ${error.message}`,
			},
		);
	};

	const {
		data: integrations,
		error: integrationError,
		isFetching: integrationFetching,
	}: UseQueryResult<ComboItem[], Error> = useQuery({
		queryKey: ["integrationTypes", orgId, destinationId],
		queryFn: () => fetchIntegrationTypes({ orgId, destinationId }),
		enabled: orgId !== "" && destinationId !== "",
		initialData: [],
	});

	const {
		data: requiredPropsData,
		error: requiredPropsError,
		isFetching: requiredPropsFetching,
	}: UseQueryResult<
		{ deviceComboItems: ComboItem[]; reqPropArr: Property[] },
		Error
	> = useQuery({
		queryKey: ["requiredProperties", orgId, destinationId, integrationId],
		queryFn: () =>
			fetchRequiredProperties({ orgId, destinationId, integrationId }),
		enabled: orgId !== "" && destinationId !== "" && integrationId !== "",
		initialData: { deviceComboItems: [], reqPropArr: [] },
	});

	useEffect(() => {
		if (integrationError) {
			const notification: NotificationItem = {
				id: uuidv4(),
				message: integrationError.message,
				header: "Failed Fetching Integrations",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			};
			addNotification(notification);
			console.error("Fetching integrations failed:", integrationError);
		}
	}, [addNotification, integrationError]);

	useEffect(() => {
		if (requiredPropsError) {
			const notification: NotificationItem = {
				id: uuidv4(),
				message: requiredPropsError.message,
				header: "Failed Fetching Required Properties",
				notification_type: "error",
				timestamp: new Date().toISOString(),
			};
			addNotification(notification);
			console.error("Fetching required properties failed:", requiredPropsError);
		}
	}, [addNotification, requiredPropsError]);

	useEffect(() => {
		if (requiredPropsData) {
			setDeviceTypes(requiredPropsData.deviceComboItems);
			setSelectedDeviceTypes([]);
			setExpireAtDate(undefined);
			setProperties(requiredPropsData.reqPropArr);
		}
	}, [requiredPropsData]);

	const convertDestinations = useMemo(
		() =>
			(destinations: Map<string, Destinations>): ComboItem[] => {
				if (!destinations) return [];
				const comboItems: ComboItem[] = [];

				for (const destination of destinations) {
					comboItems.push({
						label: destination[1].name,
						value: destination[1].id,
					});
				}
				return comboItems;
			},
		[],
	);

	const requiredPropertiesChunks = useMemo(
		() => chunkArray(properties === null ? [] : properties.slice(1), 2),
		[properties],
	);

	return (
		<Credenza>
			<CredenzaTrigger asChild>
				<Button className="bg-button-color text-dark-text hover:bg-blue-400 font-bold py-2 px-4 rounded">
					+
				</Button>
			</CredenzaTrigger>
			<CredenzaContent className="bg-gray-section">
				<CredenzaHeader>
					<CredenzaTitle className="text-3xl font-extrabold">
						Integration
					</CredenzaTitle>
				</CredenzaHeader>
				<form onSubmit={handleSubmit}>
					<CredenzaBody>
						{/*Destination/Integration Row*/}
						<div className="flex flex-col gap-4 justify-center items-center">
							<Combobox
								options={convertDestinations(destinations)}
								type={"Distribution"}
								classname="w-3/4 border-border-color"
								value={destinationId}
								setValue={setDestinationId}
							/>
							<Combobox
								options={integrations}
								type={"Integration"}
								classname="w-3/4 border-border-color"
								value={integrationId}
								setValue={setIntegrationId}
								isLoading={integrationFetching}
							/>
						</div>
						{/* Spinner */}
						{requiredPropsFetching && (
							<div className="flex flex-row gap-4 justify-center items-center pt-8 w-full">
								<ClipLoader color="#4068E3" speedMultiplier={0.7} />
							</div>
						)}
						{properties.length > 0 && (
							<>
								<div className="max-h-96 overflow-y-auto scroll-smooth focus:scroll-auto mt-4">
									<div className="flex flex-row gap-4 justify-start items-center">
										{/*Integration Name*/}
										<Label className="block w-full text-md font-medium text-gray-700 w-24/50">
											Integration Name
											<Input
												className="border-border-color"
												placeholder="Enter Integration Name..."
												onChange={(
													event: React.ChangeEvent<HTMLInputElement>,
												) => {
													setDistributionManagerName(event.target.value);
												}}
												value={distributionManagerName}
												name="integration name"
											/>
										</Label>
										{/*Expire At*/}
										<Label className="block text-md font-medium text-gray-700 w-24/50">
											Expire-At (optional)
											<Popover>
												<PopoverTrigger asChild>
													<Button
														variant={"outline"}
														className={cn(
															"flex items-center justify-between text-left font-normal w-full border-border-color pr-1.5",
															!expireAtDate && "text-muted-foreground",
														)}
													>
														<div className="flex justify-between items-center w-full">
															<div className="flex items-center">
																<CalendarIcon className="mr-2 h-3 w-3" />
																{expireAtDate ? (
																	format(expireAtDate, "PPP")
																) : (
																	<span>Pick a date</span>
																)}
															</div>
															<button
																onClick={(event) => {
																	event.stopPropagation();
																	setExpireAtDate(undefined);
																}}
																className="bg-transparent p-0 ml-4"
																type="button"
															>
																<AiFillCloseCircle className="w-4 h-4" />
															</button>
														</div>
													</Button>
												</PopoverTrigger>
												<PopoverContent className="w-auto p-0" align="start">
													<Calendar
														mode="single"
														selected={expireAtDate}
														onSelect={(selectedDate: Date | undefined) => {
															setExpireAtDate(selectedDate);
														}}
														initialFocus
														className="bg-white"
													/>
												</PopoverContent>
											</Popover>
										</Label>
									</div>

									<div className="flex flex-row gap-4 justify-start items-center pt-4">
										{/*Device Type*/}
										<Label className="block w-full text-md font-medium text-gray-700 w-24/50">
											Device Type
											<TooltipProvider>
												<Tooltip>
													<TooltipTrigger type="button">
														<IoInformationCircleOutline className="text-border-color ml-2" />
													</TooltipTrigger>
													<TooltipContent>
														<p className="bg-white rounded-md p-2 text-dark-text shadow-lg border-border-color">
															if available, <strong>RAMP_ID</strong> is usually
															the prefered option
														</p>
													</TooltipContent>
												</Tooltip>
											</TooltipProvider>
											<MultiSelect
												options={deviceTypes}
												type={"Device Type"}
												className="w-full border-border-color"
												selected={selectedDeviceTypes}
												onChange={setSelectedDeviceTypes}
												selectAll={false}
											/>
										</Label>
										{/**/}
										{properties.length > 0 && (
											<Label className="block w-full text-md font-medium text-gray-700 w-24/50">
												{properties[0].name}
												{properties[0].description !== "" && (
													<TooltipProvider>
														<Tooltip>
															<TooltipTrigger type="button">
																<IoInformationCircleOutline className="text-border-color ml-2" />
															</TooltipTrigger>
															<TooltipContent>
																<p className="bg-white rounded-md p-2 text-dark-text shadow-lg border-border-color">
																	{properties[0].description}
																</p>
															</TooltipContent>
														</Tooltip>
													</TooltipProvider>
												)}
												<Input
													className="border-border-color"
													placeholder={`Enter ${properties[0].name} Name...`}
													name={properties[0].name}
													onChange={(e) => handleInputChange(e, 0)}
												/>
											</Label>
										)}
									</div>
									{properties.length > 1 &&
										requiredPropertiesChunks.map((chunk, index) =>
											chunk.map((property, propertyIndex) => (
												<div
													key={`${index}_${property.name}`}
													className="flex flex-row gap-4 justify-start items-center pt-4"
												>
													<Label className="block w-full text-md font-medium text-gray-700 w-24/50">
														{property.name}
														{property.description !== "" && (
															<TooltipProvider>
																<Tooltip>
																	<TooltipTrigger type="button">
																		<IoInformationCircleOutline className="text-border-color ml-2" />
																	</TooltipTrigger>
																	<TooltipContent>
																		<p className="bg-white rounded-md p-2 text-dark-text shadow-lg border-border-color">
																			{property.description}
																		</p>
																	</TooltipContent>
																</Tooltip>
															</TooltipProvider>
														)}
														<Input
															placeholder={`Enter ${property.name}...`}
															className="border-border-color"
															name={property.name}
															onChange={(e) =>
																handleInputChange(
																	e,
																	index * 2 + propertyIndex + 1,
																)
															}
														/>
													</Label>
												</div>
											)),
										)}
								</div>
							</>
						)}
					</CredenzaBody>
					<CredenzaFooter>
						<Button
							className="font-semi-bold py-2 px-4 rounded text-dark-text mt-4 bg-button-color text-dark-text hover:bg-blue-400"
							disabled={
								integrationId === "" ||
								destinationId === "" ||
								properties === null ||
								distributionManagerName === "" ||
								selectedDeviceTypes.length === 0
							}
							type="submit"
						>
							Create
						</Button>
					</CredenzaFooter>
				</form>
			</CredenzaContent>
		</Credenza>
	);
};

export default IntegrationModal;
